// Updates from V2 to V3
// expected/unexpected cost

import React, { useState, useEffect } from "react";
import { Collapse } from "react-collapse";
import { MinusOutlined } from "@ant-design/icons";
import { CalculateHeader as Header, Icon, Link, Text, TableCell } from "components";
import CustomSelect from "components/molecules/Select";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "utils/object";
import { Badge } from "antd";
import { getToken } from "services/utils";
import { disablePreference } from "services/api";
import Actions from "store/report/actions";
import OptimizeModal from "../OptimizeModalV3";
import { formatCurrency } from "utils/currency";
import FilterSortButton from "components/molecules/FilterSortButton";
import { useWhatIfContext, WhatIfStateProvider } from "./context";
import { accumulateItems } from "./createItems";
import { CurrencyCellPair, ExpandIcon } from "./components";
import { RowItemTitle } from "./components";
import { colorFromLevel, Row, Wrapper, FlexWrapper, InnerWrapper, CardsWrapper } from "./styles";
import { OptWrapper, HideMobile } from "./styles";
import { PeriodOptions, FieldOptions, CompanyOptions } from "./constants";
import { CustomCollapse } from "./components";
import { getFieldKey } from "./utils";
import {
  RecursiveContextProvider,
  RecursiveCostTwoColumn,
  RecursiveHeader,
  addMissingAttributesToBreakdown,
  useRecursiveWhatIfContext,
} from "./RecursiveWhatIf";
import { useWindowDimensions } from "utils/window";

const WhatIfV2 = ({
  report,
  birthParentPlans,
  partnerPlans,
  updatePreference,
  allOptimizations,
  customOptimizations,
  forceUpdateState,
  toggleSideBar,
  toggleAssumptions,
}) => {
  const dispatch = useDispatch();
  const { preferences } = report;
  const optKeys = Object.keys(preferences).reverse();
  const secondaryOption = optKeys.find((_key) => preferences[_key].apply);
  const { width } = useWindowDimensions();

  const primaryRowsLevel = preferences["1"].hsaHraImpact === "spending-only" ? 1 : 2;

  const [options, setOptions] = useState({
    primary: "1",
    secondary: secondaryOption,
  });

  const [customPrefrenceModal, setCustomPrefrenceModal] = useState(false);

  const customPreferenceOne = useSelector((state) => state.report.customPreferenceOne);
  const customPreferenceTwo = useSelector((state) => state.report.customPreferenceTwo);

  useEffect(() => {
    const optKeys = Object.keys(preferences).reverse();
    const secondaryOption = optKeys.find((_key) => preferences[_key].apply);

    setOptions({ primary: "1", secondary: secondaryOption });
  }, [allOptimizations, preferences]);

  const getValue = (before, after, period) => {
    const returnNum = (num) => {
      if (typeof num === "number") return num;
      else return false;
    };

    const valueBefore = before ? returnNum(before.total) || before.total.value : 0;
    const valueAfter = after ? returnNum(after.total) || (after.total.value || 0) + (after.remaining || 0) : 0;
    const valueTotal = period === "Before" ? valueBefore : period === "After" ? valueAfter : valueBefore + valueAfter;

    return valueTotal;
  };

  const {
    expand,
    setExpand,
    handleExpand,
    isExpanded,
    showRows,
    showSubRows,
    handleShow,
    handleSubShow,
    isSingleCompany,
  } = useWhatIfContext();

  const { resetExpand } = useRecursiveWhatIfContext();

  useEffect(() => {
    resetExpand();
  }, [forceUpdateState]);

  // render sub row cost
  const renderSubCost = (
    before = [],
    after = [],
    unExpectedCost,
    expectedBefore = [],
    expectedAfter = [],
    expectedCost,
    period,
    type,
    totalElement,
  ) => {
    // idx 0 = unExpected, idx 1 = expected;
    let reRender = false;
    let items = {};
    const costs = { unExpectedCost, expectedCost };
    accumulateItems({ items, source: before, index: 0, field: type, period: PeriodOptions.Before, ...costs });
    accumulateItems({ items, source: after, index: 0, field: type, period: PeriodOptions.After, ...costs });
    accumulateItems({ items, source: expectedBefore, index: 1, field: type, period: PeriodOptions.Before, ...costs });
    accumulateItems({ items, source: expectedAfter, index: 1, field: type, period: PeriodOptions.After, ...costs });

    const showItems = Object.keys(items);
    const { companyA, companyB } = showSubRows;

    // sets showSubRows true for items with values
    if (type === getFieldKey({ field: FieldOptions.Premiums })) {
      let newFieldsA = {};
      let newFieldsB = {};

      if (companyA.name) {
        if (items[getFieldKey({ field: FieldOptions.Premiums, period: PeriodOptions.Before })]) {
          if (
            items[getFieldKey({ field: FieldOptions.Premiums, period: PeriodOptions.Before })][`${companyA.name}`] &&
            !companyA[getFieldKey({ field: FieldOptions.Premiums, period: PeriodOptions.Before })]
          ) {
            newFieldsA[getFieldKey({ field: FieldOptions.Premiums, period: PeriodOptions.Before })] = true;
            reRender = true;
          }

          if (
            items[getFieldKey({ field: FieldOptions.Premiums, period: PeriodOptions.Before })].PremiumSubRows[
              `${companyA.name}`
            ] &&
            items[getFieldKey({ field: FieldOptions.Premiums, period: PeriodOptions.Before })].PremiumSubRows[
              `${companyA.name}`
            ].SpousalSurcharges > 0
          ) {
            newFieldsA["Company Details A"] = true;
          }
        }
        if (items[getFieldKey({ field: FieldOptions.Premiums, period: PeriodOptions.After })]) {
          if (
            items[getFieldKey({ field: FieldOptions.Premiums, period: PeriodOptions.After })][`${companyA.name}`] &&
            !companyA[getFieldKey({ field: FieldOptions.Premiums, period: PeriodOptions.After })]
          ) {
            newFieldsA[getFieldKey({ field: FieldOptions.Premiums, period: PeriodOptions.After })] = true;
            reRender = true;
          }
          if (
            items[getFieldKey({ field: FieldOptions.Premiums, period: PeriodOptions.After })].PremiumSubRows[
              `${companyA.name}`
            ] &&
            items[getFieldKey({ field: FieldOptions.Premiums, period: PeriodOptions.After })].PremiumSubRows[
              `${companyA.name}`
            ].SpousalSurcharges > 0
          ) {
            newFieldsA["Company Details A"] = true;
          }
        }
      }

      if (companyB.name) {
        if (items[getFieldKey({ field: FieldOptions.Premiums, period: PeriodOptions.Before })]) {
          if (
            items[getFieldKey({ field: FieldOptions.Premiums, period: PeriodOptions.Before })][`${companyB.name}`] &&
            !companyB[getFieldKey({ field: FieldOptions.Premiums, period: PeriodOptions.Before })]
          ) {
            newFieldsB[getFieldKey({ field: FieldOptions.Premiums, period: PeriodOptions.Before })] = true;
            reRender = true;
          }

          if (
            items[getFieldKey({ field: FieldOptions.Premiums, period: PeriodOptions.Before })].PremiumSubRows[
              `${companyB.name}`
            ] &&
            items[getFieldKey({ field: FieldOptions.Premiums, period: PeriodOptions.Before })].PremiumSubRows[
              `${companyB.name}`
            ].SpousalSurcharges > 0
          ) {
            newFieldsB["Company Details B"] = true;
          }
        }
        if (items[getFieldKey({ field: FieldOptions.Premiums, period: PeriodOptions.After })]) {
          if (
            items[getFieldKey({ field: FieldOptions.Premiums, period: PeriodOptions.After })][`${companyB.name}`] &&
            !companyB[getFieldKey({ field: FieldOptions.Premiums, period: PeriodOptions.After })]
          ) {
            newFieldsB[getFieldKey({ field: FieldOptions.Premiums, period: PeriodOptions.After })] = true;
            reRender = true;
          }

          if (
            items[getFieldKey({ field: FieldOptions.Premiums, period: PeriodOptions.After })].PremiumSubRows[
              `${companyB.name}`
            ] &&
            items[getFieldKey({ field: FieldOptions.Premiums, period: PeriodOptions.After })].PremiumSubRows[
              `${companyB.name}`
            ].SpousalSurcharges > 0
          ) {
            newFieldsB["Company Details B"] = true;
          }
        }
      }

      if (reRender) handleSubShow("companies", null, newFieldsA, newFieldsB);
    } else if (type === getFieldKey({ field: FieldOptions.Surcharges })) {
      let newFieldsA = {};
      let newFieldsB = {};
      if (companyA.name) {
        if (items[getFieldKey({ field: FieldOptions.Surcharges, period: PeriodOptions.Before })]) {
          if (
            items[getFieldKey({ field: FieldOptions.Surcharges, period: PeriodOptions.Before })][`${companyA.name}`] &&
            !companyA[getFieldKey({ field: FieldOptions.Surcharges, period: PeriodOptions.Before })]
          ) {
            newFieldsA[getFieldKey({ field: FieldOptions.Surcharges, period: PeriodOptions.Before })] = true;
            reRender = true;
          }
        }
        if (items[getFieldKey({ field: FieldOptions.Surcharges, period: PeriodOptions.After })]) {
          if (
            items[getFieldKey({ field: FieldOptions.Surcharges, period: PeriodOptions.After })][`${companyA.name}`] &&
            !companyA[getFieldKey({ field: FieldOptions.Surcharges, period: PeriodOptions.After })]
          ) {
            newFieldsA[getFieldKey({ field: FieldOptions.Surcharges, period: PeriodOptions.After })] = true;
            reRender = true;
          }
        }
      }

      if (companyB.name) {
        if (items[getFieldKey({ field: FieldOptions.Surcharges, period: PeriodOptions.Before })]) {
          if (
            items[getFieldKey({ field: FieldOptions.Surcharges, period: PeriodOptions.Before })][`${companyB.name}`] &&
            !companyB[getFieldKey({ field: FieldOptions.Surcharges, period: PeriodOptions.Before })]
          ) {
            newFieldsB[getFieldKey({ field: FieldOptions.Surcharges, period: PeriodOptions.Before })] = true;
            reRender = true;
          }
        }
        if (items[getFieldKey({ field: FieldOptions.Surcharges, period: PeriodOptions.After })]) {
          if (
            items[getFieldKey({ field: FieldOptions.Surcharges, period: PeriodOptions.After })][`${companyB.name}`] &&
            !companyB[getFieldKey({ field: FieldOptions.Surcharges, period: PeriodOptions.After })]
          ) {
            newFieldsB[getFieldKey({ field: FieldOptions.Surcharges, period: PeriodOptions.After })] = true;
            reRender = true;
          }
        }
      }

      if (reRender) handleSubShow("companies", null, newFieldsA, newFieldsB);
    } else if (type === getFieldKey({ field: FieldOptions.OptOutPayment })) {
      let newFieldsA = {};
      let newFieldsB = {};
      if (companyA.name) {
        if (items[getFieldKey({ field: FieldOptions.OptOutPayment, period: PeriodOptions.Before })]) {
          if (
            items[getFieldKey({ field: FieldOptions.OptOutPayment, period: PeriodOptions.Before })][
              `${companyA.name}`
            ] &&
            !companyA[getFieldKey({ field: FieldOptions.OptOutPayment, period: PeriodOptions.Before })]
          ) {
            newFieldsA[getFieldKey({ field: FieldOptions.OptOutPayment, period: PeriodOptions.Before })] = true;
            reRender = true;
          }
        }
        if (items["Opt-Out Payment After"]) {
          if (items["Opt-Out Payment After"][`${companyA.name}`] && !companyA["Opt-Out Payment After"]) {
            newFieldsA["Opt-Out Payment After"] = true;
            reRender = true;
          }
        }
      }

      if (companyB.name) {
        if (items[getFieldKey({ field: FieldOptions.OptOutPayment, period: PeriodOptions.Before })]) {
          if (
            items[getFieldKey({ field: FieldOptions.OptOutPayment, period: PeriodOptions.Before })][
              `${companyB.name}`
            ] &&
            !companyB[getFieldKey({ field: FieldOptions.OptOutPayment, period: PeriodOptions.Before })]
          ) {
            newFieldsB[getFieldKey({ field: FieldOptions.OptOutPayment, period: PeriodOptions.Before })] = true;
            reRender = true;
          }
        }
        if (items["Opt-Out Payment After"]) {
          if (items["Opt-Out Payment After"][`${companyB.name}`] && !companyB["Opt-Out Payment After"]) {
            newFieldsB["Opt-Out Payment After"] = true;
            reRender = true;
          }
        }
      }

      if (reRender) handleSubShow("companies", null, newFieldsA, newFieldsB);
    } else if (type === "Insurance") {
      showItems.forEach((item) => {
        reRender = false;
        if (items[item]) {
          const company = item.replace(" Plan", "");
          let newCompany;
          if (companyA.name === company || companyA.altName === company) {
            newCompany = companyA;
            const rowTitles = Object.keys(items[company]);
            rowTitles.forEach((title) => {
              if (!newCompany.insuranceFields[title] && (items[item][title][0] || items[item][title][1])) {
                newCompany.insuranceFields[title] = true;
                reRender = "companyA";
              }
            });
          } else if (companyB.name === company || companyB.altName === company) {
            newCompany = companyB;
            const rowTitles = Object.keys(items[company]);
            rowTitles.forEach((title) => {
              if (!newCompany.insuranceFields[title] && (items[item][title][0] || items[item][title][1])) {
                newCompany.insuranceFields[title] = true;
                reRender = "companyB";
              }
            });
          }
          if (reRender) handleSubShow(reRender, newCompany);
        }
      });
    } else if (type === getFieldKey({ field: FieldOptions.OutOfNetwork })) {
      const newOutOfNetworkFields = showSubRows[getFieldKey({ field: FieldOptions.OutOfNetwork })];
      showItems.forEach((item) => {
        if (!newOutOfNetworkFields[item] && (items[item][0] > 0 || items[item][1] > 0)) {
          newOutOfNetworkFields[item] = true;
          reRender = getFieldKey({ field: FieldOptions.OutOfNetwork });
        }
      });
      if (reRender) handleSubShow(reRender, newOutOfNetworkFields);
    } else if (type === getFieldKey({ field: FieldOptions.NotCovered })) {
      const notCoveredItems = items[getFieldKey({ field: FieldOptions.NotCovered })];
      let newFieldsA = {};
      let newFieldsB = {};

      const companyANotCovered =
        (notCoveredItems && notCoveredItems[companyA.name]) ||
        (notCoveredItems && notCoveredItems[companyA.altName]) ||
        [];
      if (companyANotCovered && companyANotCovered.some((el) => el > 0)) {
        if (!companyA[type]) {
          newFieldsA[type] = true;
          reRender = true;
        }
      }
      const companyBNotCovered =
        (notCoveredItems && notCoveredItems[companyB.name]) ||
        (notCoveredItems && notCoveredItems[companyB.altName]) ||
        [];
      if (companyBNotCovered && companyBNotCovered.some((el) => el > 0)) {
        if (!companyB[type]) {
          newFieldsB[type] = true;
          reRender = true;
        }
      }
      if (reRender) handleSubShow("companies", null, newFieldsA, newFieldsB);
    } else if (type === "HSA/HRA Impact") {
      const hsaHraImpactItems = showSubRows["HSA/HRA Impact"];
      showItems.forEach((item) => {
        if (!hsaHraImpactItems[item] && (items[item][0] !== 0 || items[item][1] !== 0)) {
          reRender = "HSA/HRA Impact";
          hsaHraImpactItems[item] = true;
        }
      });
      if (reRender) handleSubShow(reRender, hsaHraImpactItems);
    } else if (showItems) {
      const currentType = period ? `${type} ${period}` : type;
      let newFieldsA = {};
      let newFieldsB = {};

      if (!companyA[currentType] && items[companyA.name]) {
        newFieldsA[currentType] = true;
        reRender = true;
      }
      if (!companyB[currentType] && items[companyB.name]) {
        newFieldsB[currentType] = true;
        reRender = true;
      }
      if (reRender) handleSubShow("companies", null, newFieldsA, newFieldsB);
    }

    const category = period ? `${type} ${period}` : type;
    const rowType = showRows[category];

    const renderItem = ({
      item,
      fontSize = "14",
      category,
      subCategory,
      underline,
      key = "0",
      hideData = false,
      largeText = false,
    }) => {
      return (
        <>
          <Row
            color="tertiary"
            justify="center"
            hide={!rowType}
            key={key}
            category={category}
            subCategory={subCategory}
            customHeight={largeText && width < 1636 && "65px"}
          >
            {hideData ? (
              <></>
            ) : (
              <>
                <CurrencyCellPair values={items[item]} fontSize={fontSize} underline={underline} />
              </>
            )}
          </Row>
        </>
      );
    };

    const renderInsuranceSubItems = (company, show) => {
      const { insuranceFields = {}, name, altName } = company;
      const { Deductible, Copay, Coinsurance } = insuranceFields;
      const companyDetails = items[name] || items[altName] || {};
      const expanded = isExpanded({ field: FieldOptions.Insurance, companyType: show });

      if (Deductible || Copay || Coinsurance) {
        return (
          <>
            {expanded ? (
              !isSingleCompany && <Row color="tertiary" justify="center" category />
            ) : (
              <Row color="tertiary" justify="center" category>
                <CurrencyCellPair values={companyDetails.Total} fontSize="14" />
              </Row>
            )}

            <Collapse isOpened={expanded}>
              {Deductible && (
                <Row color="tertiary" justify="center" subCategory>
                  <CurrencyCellPair values={companyDetails.Deductible} fontSize="12" />
                </Row>
              )}
              {Copay && (
                <Row color="tertiary" justify="center" subCategory>
                  <CurrencyCellPair values={companyDetails.Copay} fontSize="12" />
                </Row>
              )}
              {Coinsurance && (
                <Row color="tertiary" justify="center" subCategory>
                  <CurrencyCellPair values={companyDetails.Coinsurance} fontSize="12" />
                </Row>
              )}

              {!isSingleCompany && (
                <Row color="tertiary" justify="center" category>
                  <CurrencyCellPair values={companyDetails.Total} fontSize="14" />
                </Row>
              )}
            </Collapse>
          </>
        );
      }
    };

    const renderPremiumSubItems = (period, isPremiumsAfter, totalNode) => {
      const companyAPremiums = companyA[period]; // true / false
      const companyBPremiums = companyB[period]; // true / false

      return (
        <>
          <Collapse isOpened={expand[period] || !isPremiumsAfter}>
            {companyAPremiums && (
              <>
                <Row color="tertiary" justify="center" category={!isPremiumsAfter} subCategory={isPremiumsAfter}>
                  {expand["Company Details A"] ? (
                    <></>
                  ) : (
                    <CurrencyCellPair
                      values={items?.[period]?.[companyA.name]}
                      fontSize={isPremiumsAfter ? "12" : "14"}
                    />
                  )}
                </Row>
              </>
            )}
            <Collapse isOpened={expand["Company Details A"]}>
              <>
                <Row color="tertiary" justify="center" category={!isPremiumsAfter} subCategory={isPremiumsAfter}>
                  <CurrencyCellPair
                    values={
                      items?.[period]?.PremiumSubRows?.[companyA.name]?.[getFieldKey({ field: FieldOptions.Premiums })]
                    }
                    fontSize={isPremiumsAfter ? "12" : "14"}
                  />
                </Row>
                <Row color="tertiary" justify="center" category={!isPremiumsAfter} subCategory={isPremiumsAfter}>
                  <CurrencyCellPair
                    values={items?.[period]?.PremiumSubRows?.[companyA.name]?.SpousalSurcharges}
                    fontSize={isPremiumsAfter ? "12" : "14"}
                  />
                </Row>
              </>
              <Row color="tertiary" justify="center" category={!isPremiumsAfter} subCategory={isPremiumsAfter}>
                <CurrencyCellPair values={items?.[period]?.[companyA.name]} fontSize={isPremiumsAfter ? "12" : "14"} />
              </Row>
            </Collapse>

            {companyBPremiums && (
              <Row color="tertiary" justify="center" category={!isPremiumsAfter} subCategory={isPremiumsAfter}>
                {expand["Company Details B"] ? (
                  <></>
                ) : (
                  <>
                    <CurrencyCellPair
                      values={items?.[period]?.[companyB.name]}
                      fontSize={isPremiumsAfter ? "12" : "14"}
                    />
                  </>
                )}
              </Row>
            )}
            <Collapse isOpened={expand["Company Details B"]}>
              <>
                <Row color="tertiary" justify="center" category={!isPremiumsAfter} subCategory={isPremiumsAfter}>
                  <CurrencyCellPair
                    values={
                      items?.[period]?.PremiumSubRows?.[companyB.name]?.[getFieldKey({ field: FieldOptions.Premiums })]
                    }
                    fontSize={isPremiumsAfter ? "12" : "14"}
                  />
                </Row>
                <Row color="tertiary" justify="center" category={!isPremiumsAfter} subCategory={isPremiumsAfter}>
                  <CurrencyCellPair
                    values={items?.[period]?.PremiumSubRows?.[companyB.name]?.SpousalSurcharges}
                    fontSize={isPremiumsAfter ? "12" : "14"}
                  />
                </Row>
                <Row color="tertiary" justify="center" category={!isPremiumsAfter} subCategory={isPremiumsAfter}>
                  <CurrencyCellPair
                    values={items?.[period]?.[companyB.name]}
                    fontSize={isPremiumsAfter ? "12" : "14"}
                  />
                </Row>
              </>
            </Collapse>
            {isPremiumsAfter ? <>{totalNode}</> : <></>}
          </Collapse>
        </>
      );
    };

    const renderSurchargeSubItems = (period, isSurchargesAfter, totalNode) => {
      const companyASurcharges = companyA[period]; // true / false
      const companyBSurcharges = companyB[period]; // true / false

      return (
        <Collapse isOpened={expand[period] || !isSurchargesAfter}>
          {companyASurcharges && (
            <Row color="tertiary" justify="center" category={!isSurchargesAfter} subCategory={isSurchargesAfter}>
              <CurrencyCellPair values={items?.[period]?.[companyA.name]} fontSize={isSurchargesAfter ? "12" : "14"} />
            </Row>
          )}
          {companyBSurcharges && (
            <Row color="tertiary" justify="center" category={!isSurchargesAfter} subCategory={isSurchargesAfter}>
              <CurrencyCellPair values={items?.[period]?.[companyB.name]} fontSize={isSurchargesAfter ? "12" : "14"} />
            </Row>
          )}
          {totalNode}
        </Collapse>
      );
    };

    const renderOptOutPaymentSubItems = (period, isOptOutPaymentAfter, totalNode) => {
      const companyAOptOutPayment = companyA[period]; // true / false
      const companyBOptOutPayment = companyB[period]; // true / false

      return (
        <Collapse isOpened={expand[period] || !isOptOutPaymentAfter}>
          {companyAOptOutPayment && (
            <Row color="tertiary" justify="center" category={!isOptOutPaymentAfter} subCategory={isOptOutPaymentAfter}>
              <CurrencyCellPair
                values={items?.[period]?.[companyA.name]}
                fontSize={isOptOutPaymentAfter ? "12" : "14"}
              />
            </Row>
          )}
          {companyBOptOutPayment && (
            <Row color="tertiary" justify="center" category={!isOptOutPaymentAfter} subCategory={isOptOutPaymentAfter}>
              <CurrencyCellPair
                values={items?.[period]?.[companyB.name]}
                fontSize={isOptOutPaymentAfter ? "12" : "14"}
              />
            </Row>
          )}
          {totalNode}
        </Collapse>
      );
    };

    const renderNotCoveredSubItems = ({ company }) => {
      const costs = items[getFieldKey({ field: FieldOptions.NotCovered })][company.name] ||
        items[getFieldKey({ field: FieldOptions.NotCovered })][company.altName] || [0, 0];

      return (
        <Row color="tertiary" justify="center" hide={!rowType} key={company.name} category={category}>
          <CurrencyCellPair values={costs} fontSize="14" />
        </Row>
      );
    };

    // render subRows
    if (type === getFieldKey({ field: FieldOptions.Premiums })) {
      const isPremiumsAfter =
        companyA[getFieldKey({ field: FieldOptions.Premiums, period: PeriodOptions.After })] ||
        companyB[getFieldKey({ field: FieldOptions.Premiums, period: PeriodOptions.After })];
      return (
        <Collapse isOpened={expand[category]}>
          {isPremiumsAfter && (
            <Row color="tertiary" justify="center" category>
              {isExpanded({ field: FieldOptions.Premiums, period: PeriodOptions.Before }) ? (
                <></>
              ) : (
                <CurrencyCellPair
                  values={items[getFieldKey({ field: FieldOptions.Premiums, period: PeriodOptions.Before })]?.Total}
                  fontSize="14"
                />
              )}
            </Row>
          )}
          {renderPremiumSubItems(
            getFieldKey({ field: FieldOptions.Premiums, period: PeriodOptions.Before }),
            isPremiumsAfter,
            <Row color="tertiary" justify="center" category>
              <CurrencyCellPair
                values={items[getFieldKey({ field: FieldOptions.Premiums, period: PeriodOptions.Before })]?.Total}
                fontSize="14"
              />
            </Row>,
          )}
          {isPremiumsAfter && (
            <>
              <Row color="tertiary" justify="center" category>
                {isExpanded({ field: FieldOptions.Premiums, period: PeriodOptions.After }) ? (
                  <></>
                ) : (
                  <>
                    <CurrencyCellPair
                      values={items[getFieldKey({ field: FieldOptions.Premiums, period: PeriodOptions.After })]?.Total}
                      fontSize="14"
                    />
                  </>
                )}
              </Row>
              {renderPremiumSubItems(
                getFieldKey({ field: FieldOptions.Premiums, period: PeriodOptions.After }),
                isPremiumsAfter,
                <Row color="tertiary" justify="center" category>
                  <CurrencyCellPair
                    values={items[getFieldKey({ field: FieldOptions.Premiums, period: PeriodOptions.After })]?.Total}
                    fontSize="14"
                  />
                </Row>,
              )}
            </>
          )}
          {totalElement}
        </Collapse>
      );
    } else if (type === getFieldKey({ field: FieldOptions.Surcharges })) {
      const isSurchargesAfter =
        companyA[getFieldKey({ field: FieldOptions.Surcharges, period: PeriodOptions.After })] ||
        companyB[getFieldKey({ field: FieldOptions.Surcharges, period: PeriodOptions.After })];
      return (
        <Collapse isOpened={expand[category]}>
          {isSurchargesAfter && (
            <Row color="tertiary" justify="center" category>
              {expand[getFieldKey({ field: FieldOptions.Surcharges, period: PeriodOptions.Before })] ? (
                <></>
              ) : (
                <CurrencyCellPair
                  values={items[getFieldKey({ field: FieldOptions.Surcharges, period: PeriodOptions.Before })]?.Total}
                  fontSize="14"
                />
              )}
            </Row>
          )}
          {renderSurchargeSubItems(
            getFieldKey({ field: FieldOptions.Surcharges, period: PeriodOptions.Before }),
            isSurchargesAfter,
            <Row color="tertiary" justify="center" category>
              <CurrencyCellPair
                values={items[getFieldKey({ field: FieldOptions.Surcharges, period: PeriodOptions.Before })]?.Total}
                fontSize="14"
              />
            </Row>,
          )}
          {isSurchargesAfter && (
            <>
              <Row color="tertiary" justify="center" category>
                {isExpanded({ field: FieldOptions.Surcharges, period: PeriodOptions.After }) ? (
                  <></>
                ) : (
                  <CurrencyCellPair
                    values={items[getFieldKey({ field: FieldOptions.Surcharges, period: PeriodOptions.After })]?.Total}
                    fontSize="14"
                  />
                )}
              </Row>
              {renderSurchargeSubItems(
                getFieldKey({ field: FieldOptions.Surcharges, period: PeriodOptions.After }),
                isSurchargesAfter,
                <Row color="tertiary" justify="center" category>
                  <CurrencyCellPair
                    values={items[getFieldKey({ field: FieldOptions.Surcharges, period: PeriodOptions.After })]?.Total}
                    fontSize="14"
                  />
                </Row>,
              )}
            </>
          )}
          {totalElement}
        </Collapse>
      );
    } else if (type === "Insurance") {
      return (
        <Collapse isOpened={isExpanded({ field: FieldOptions.Insurance })}>
          {renderInsuranceSubItems(companyA, "companyA")}
          {renderInsuranceSubItems(companyB, "companyB")}
          {totalElement}
        </Collapse>
      );
    } else if (type === getFieldKey({ field: FieldOptions.OutOfNetwork })) {
      const outOfNetwork = showSubRows[getFieldKey({ field: FieldOptions.OutOfNetwork })];

      return (
        <Collapse isOpened={expand[category]}>
          {renderItem({
            item: "Cost of Visits",
            fontSize: "14",
            category: true,
            key: 0,
          })}

          {renderItem({
            item: "Reimbursement from Insurance",
            fontSize: "14",
            category: true,
            key: 1,
            hideData: expand["Reimbursement from Insurance"],
          })}
          <Collapse isOpened={expand["Reimbursement from Insurance"]}>
            {outOfNetwork["Negotiated Rate"] &&
              renderItem({
                item: "Negotiated Rate",
                fontSize: "12",
                subCategory: true,
                key: 0,
              })}

            {outOfNetwork["Out-of-Pocket Accrual"] &&
              renderItem({
                item: "Out-of-Pocket Accrual",
                fontSize: "12",
                subCategory: true,
                hideData: expand["Out-of-Pocket Accrual"],
              })}
            <Collapse isOpened={expand["Out-of-Pocket Accrual"]}>
              {outOfNetwork["Deductibles"] &&
                renderItem({
                  item: "Deductibles",
                  fontSize: "10",
                  subCategory: true,
                  key: 1,
                })}
              {outOfNetwork["Coinsurance"] &&
                renderItem({
                  item: "Coinsurance",
                  fontSize: "10",
                  subCategory: true,
                  key: 2,
                })}
              {outOfNetwork["Out-of-Pocket Accrual"] &&
                renderItem({
                  item: "Out-of-Pocket Accrual",
                  fontSize: "12",
                  subCategory: true,
                })}
            </Collapse>
            {renderItem({
              item: "Reimbursement from Insurance",
              fontSize: "14",
              category: true,
              key: 1,
            })}
          </Collapse>
          {totalElement}
        </Collapse>
      );
    } else if (type === getFieldKey({ field: FieldOptions.NotCovered })) {
      return (
        <Collapse isOpened={expand[category]}>
          {companyA[type] &&
            renderNotCoveredSubItems({
              company: companyA,
            })}
          {companyB[type] &&
            renderNotCoveredSubItems({
              company: companyB,
            })}
          {totalElement}
        </Collapse>
      );
    } else if (type === getFieldKey({ field: FieldOptions.OptOutPayment })) {
      const isOptOutPaymentAfter = companyA["Opt-Out Payment After"] || companyB["Opt-Out Payment After"];
      return (
        <Collapse isOpened={expand[category]}>
          {isOptOutPaymentAfter && (
            <Row color="tertiary" justify="center" category>
              {isExpanded({ field: FieldOptions.OptOutPayment, period: PeriodOptions.Before }) ? (
                <></>
              ) : (
                <CurrencyCellPair
                  values={
                    items?.[getFieldKey({ field: FieldOptions.OptOutPayment, period: PeriodOptions.Before })]?.Total
                  }
                  fontSize="14"
                />
              )}
            </Row>
          )}
          {renderOptOutPaymentSubItems(
            getFieldKey({ field: FieldOptions.OptOutPayment, period: PeriodOptions.Before }),
            isOptOutPaymentAfter,
            <Row color="tertiary" justify="center" category>
              <CurrencyCellPair
                values={
                  items?.[getFieldKey({ field: FieldOptions.OptOutPayment, period: PeriodOptions.Before })]?.Total
                }
                fontSize="14"
              />
            </Row>,
          )}
          {isOptOutPaymentAfter && (
            <>
              <Row color="tertiary" justify="center" category>
                {expand["Opt-Out Payment After"] ? (
                  <></>
                ) : (
                  <CurrencyCellPair values={items?.["Opt-Out Payment After"]?.Total} fontSize="14" />
                )}
              </Row>
              {renderOptOutPaymentSubItems(
                "Opt-Out Payment After",
                isOptOutPaymentAfter,
                <Row color="tertiary" justify="center" category>
                  <CurrencyCellPair values={items?.["Opt-Out Payment After"]?.Total} fontSize="14" />
                </Row>,
              )}
            </>
          )}
          {totalElement}
        </Collapse>
      );
    } else if (type === "HSA" || type === "HRA") {
      const currentType = `${type} ${period}`;

      return (
        <Collapse isOpened={expand[category]}>
          {companyA[currentType] &&
            renderItem({
              item: companyA.name,
              fontSize: "14",
              category: true,
              key: 0,
            })}
          {companyB[currentType] &&
            renderItem({
              item: companyB.name,
              fontSize: "14",
              category: true,
              key: 1,
            })}
          {totalElement}
        </Collapse>
      );
    } else if (type === "HSA/HRA Impact") {
      return (
        <Collapse isOpened={expand["HSA/HRA Impact"]}>
          {showSubRows["HSA/HRA Impact"]["Employer HSA Contribution"] &&
            renderItem({
              item: "Employer HSA Contribution",
              fontSize: "14",
              subCategory: true,
              hideData: expand["Employer HSA Contribution"],
              largeText: true,
            })}
          <Collapse isOpened={expand["Employer HSA Contribution"]}>
            {showSubRows["HSA/HRA Impact"]["Company A Employer HSA Contribution"] &&
              renderItem({
                item: "Company A Employer HSA Contribution",
                fontSize: "12",
                subCategory: true,
              })}
            {showSubRows["HSA/HRA Impact"]["Company B Employer HSA Contribution"] &&
              renderItem({
                item: "Company B Employer HSA Contribution",
                fontSize: "12",
                subCategory: true,
              })}
            {showSubRows["HSA/HRA Impact"]["Employer HSA Contribution"] &&
              renderItem({
                item: "Employer HSA Contribution",
                fontSize: "14",
                subCategory: true,
              })}
          </Collapse>

          {showSubRows["HSA/HRA Impact"]["Reduced Tax Bill From HSA Contribution"] &&
            renderItem({
              item: "Reduced Tax Bill From HSA Contribution",
              fontSize: "14",
              subCategory: true,
              hideData: expand["Reduced Tax Bill From HSA Contribution"],
              largeText: true,
            })}
          <Collapse isOpened={expand["Reduced Tax Bill From HSA Contribution"]}>
            {showSubRows["HSA/HRA Impact"]["Company A Reduced Tax Bill From HSA Contribution"] &&
              renderItem({
                item: "Company A Reduced Tax Bill From HSA Contribution",
                fontSize: "12",
                subCategory: true,
              })}
            {showSubRows["HSA/HRA Impact"]["Company B Reduced Tax Bill From HSA Contribution"] &&
              renderItem({
                item: "Company B Reduced Tax Bill From HSA Contribution",
                fontSize: "12",
                subCategory: true,
              })}
            {showSubRows["HSA/HRA Impact"]["Reduced Tax Bill From HSA Contribution"] &&
              renderItem({
                item: "Reduced Tax Bill From HSA Contribution",
                fontSize: "14",
                subCategory: true,
              })}
          </Collapse>

          {showSubRows["HSA/HRA Impact"]["Reduced Tax Bill on HSA Investment Gains"] &&
            renderItem({
              item: "Reduced Tax Bill on HSA Investment Gains",
              fontSize: "14",
              subCategory: true,
              hideData: expand["Reduced Tax Bill on HSA Investment Gains"],
              largeText: true,
            })}
          <Collapse isOpened={expand["Reduced Tax Bill on HSA Investment Gains"]}>
            {showSubRows["HSA/HRA Impact"]["Company A Reduced Tax Bill on HSA Investment Gains"] &&
              renderItem({
                item: "Company A Reduced Tax Bill on HSA Investment Gains",
                fontSize: "12",
                subCategory: true,
              })}
            {showSubRows["HSA/HRA Impact"]["Company B Reduced Tax Bill on HSA Investment Gains"] &&
              renderItem({
                item: "Company B Reduced Tax Bill on HSA Investment Gains",
                fontSize: "12",
                subCategory: true,
              })}
            {showSubRows["HSA/HRA Impact"]["Reduced Tax Bill on HSA Investment Gains"] &&
              renderItem({
                item: "Reduced Tax Bill on HSA Investment Gains",
                fontSize: "14",
                subCategory: true,
              })}
          </Collapse>

          {showSubRows["HSA/HRA Impact"]["Investment Gains on Employer Contribution"] &&
            renderItem({
              item: "Investment Gains on Employer Contribution",
              fontSize: "14",
              subCategory: true,
              hideData: expand["Investment Gains on Employer Contribution"],
              largeText: true,
            })}
          <Collapse isOpened={expand["Investment Gains on Employer Contribution"]}>
            {showSubRows["HSA/HRA Impact"]["Company A Investment Gains on Employer Contribution"] &&
              renderItem({
                item: "Company A Investment Gains on Employer Contribution",
                fontSize: "12",
                subCategory: true,
              })}
            {showSubRows["HSA/HRA Impact"]["Company B Investment Gains on Employer Contribution"] &&
              renderItem({
                item: "Company B Investment Gains on Employer Contribution",
                fontSize: "12",
                subCategory: true,
              })}
            {showSubRows["HSA/HRA Impact"]["Investment Gains on Employer Contribution"] &&
              renderItem({
                item: "Investment Gains on Employer Contribution",
                fontSize: "14",
                subCategory: true,
              })}
          </Collapse>

          {totalElement}
        </Collapse>
      );
    }
  };

  // @todo: check why it had the two unused props
  // render row cost
  const renderCost = ({
    type,
    data,
    period,
    // event,
    // children
  }) => {
    const { before, after, expectedBefore, expectedAfter } = data;

    const unExpectedCost = getValue(before, after, period);
    if (unExpectedCost) handleShow(period ? `${type} ${period}` : type, true);

    const expectedCost = getValue(expectedBefore, expectedAfter, period);
    if (expectedCost) handleShow(period ? `${type} ${period}` : type, true);

    const modifier =
      (type === "HSA" ||
        type === "HRA" ||
        (type === getFieldKey({ field: FieldOptions.OptOutPayment }) &&
          preferences["1"].hsaHraImpact === "spending-only")) &&
      period !== "After"
        ? -1
        : 1;

    const category = period ? `${type} ${period}` : type;

    const currencyValues = [unExpectedCost * modifier, expectedCost * modifier];

    return (
      <>
        <Row color="tertiary" justify="center" hide={!showRows[period ? `${type} ${period}` : type]}>
          {!expand[category] ? <CurrencyCellPair values={currencyValues} fontSize="14" variation="bold" /> : <></>}
        </Row>
        {renderSubCost(
          before ? before.details : [],
          after ? after.details : [],
          unExpectedCost,
          expectedBefore ? expectedBefore.details : [],
          expectedAfter ? expectedAfter.details : [],
          expectedCost,
          period,
          type,
          <Row color="tertiary" justify="center" hide={!showRows[period ? `${type} ${period}` : type]}>
            <CurrencyCellPair values={currencyValues} fontSize="14" variation="bold" />
          </Row>,
        )}
      </>
    );
  };

  // render risk column
  const renderRisk = (opt, planInfoTree, id, headerTree) => {
    const { expected, unexpected, optimizationDetails, subHeading } = opt || {};
    const { birthParent } = optimizationDetails || {};

    const isToSelect = opt && isEmpty(opt);

    // const { total, charges } = unexpected
    const preference = preferences[id];

    const insuranceBreakdowns = [planInfoTree?.expected?.insurance, planInfoTree?.unexpected?.insurance];
    const hsaBenefitsBreakdowns = [planInfoTree?.expected?.hsaBenefits, planInfoTree?.unexpected?.hsaBenefits];

    const { lifeEvent = {} } = preference || {};

    let premiumCharges = {
        before: null,
        after: null,
        expectedBefore: null,
        expectedAfter: null,
      },
      surchargeCharges = {
        before: null,
        after: null,
        expectedBefore: null,
        expectedAfter: null,
      },
      insuranceCharges = {
        before: null,
        after: null,
        expectedBefore: null,
        expectedAfter: null,
      },
      outOfNetworkCharges = {
        before: null,
        after: null,
        expectedBefore: null,
        expectedAfter: null,
      },
      optOutPayments = {
        before: null,
        after: null,
        expectedBefore: null,
        expectedAfter: null,
      },
      hsaCharges = {
        before: null,
        after: null,
        expectedBefore: null,
        expectedAfter: null,
      },
      hraCharges = {
        before: null,
        after: null,
        expectedBefore: null,
        expectedAfter: null,
      },
      notCovered = {
        before: null,
        after: null,
        expectedBefore: null,
        expectedAfter: null,
      },
      hsaHraImpactCharges = {
        before: null,
        after: null,
        expectedBefore: null,
        expectedAfter: null,
      };

    if (unexpected && unexpected.charges) {
      const { charges } = unexpected;
      premiumCharges.before = charges.before.find((charge) => charge.id === "premium");
      premiumCharges.after = charges.after.find((charge) => charge.id === "premium");
      optOutPayments.before = charges.before.find((charge) => charge.id === "opt-out payment");
      optOutPayments.after = charges.after.find((charge) => charge.id === "opt-out payment");
      surchargeCharges.before = charges.before.find((charge) => charge.id === "surcharge");
      surchargeCharges.after = charges.after.find((charge) => charge.id === "surcharge");
      insuranceCharges.before = charges.before.find((charge) => charge.id === "insurance");
      outOfNetworkCharges.before = charges.before.find((charge) => charge.id === "out-of-network");
      notCovered.before = charges.before.find((charge) => charge.id === "not covered");
      hsaCharges.before = charges.before.find((charge) => charge.id === "hsa");
      hsaCharges.after = charges.after.find((charge) => charge.id === "hsa");
      hraCharges.before = charges.before.find((charge) => charge.id === "hra");
      hraCharges.after = charges.after.find((charge) => charge.id === "hra");
      hsaHraImpactCharges.before = charges.before.find((charge) => charge.id === "hsaBenefits");
      hsaHraImpactCharges.after = charges.after.find((charge) => charge.id === "hsaBenefits");
    }

    if (expected && expected.charges) {
      const { charges } = expected;
      premiumCharges.expectedBefore = charges.before.find((charge) => charge.id === "premium");
      premiumCharges.expectedAfter = charges.after.find((charge) => charge.id === "premium");
      optOutPayments.expectedBefore = charges.before.find((charge) => charge.id === "opt-out payment");
      optOutPayments.expectedAfter = charges.after.find((charge) => charge.id === "opt-out payment");
      surchargeCharges.expectedBefore = charges.before.find((charge) => charge.id === "surcharge");
      surchargeCharges.expectedAfter = charges.after.find((charge) => charge.id === "surcharge");
      insuranceCharges.expectedBefore = charges.before.find((charge) => charge.id === "insurance");
      outOfNetworkCharges.expectedBefore = charges.before.find((charge) => charge.id === "out-of-network");
      notCovered.expectedBefore = charges.before.find((charge) => charge.id === "not covered");
      hsaCharges.expectedBefore = charges.before.find((charge) => charge.id === "hsa");
      hsaCharges.expectedAfter = charges.after.find((charge) => charge.id === "hsa");
      hraCharges.expectedBefore = charges.before.find((charge) => charge.id === "hra");
      hraCharges.expectedAfter = charges.after.find((charge) => charge.id === "hra");
      hsaHraImpactCharges.expectedBefore = charges.before.find((charge) => charge.id === "hsaBenefits");
      hsaHraImpactCharges.expectedAfter = charges.after.find((charge) => charge.id === "hsaBenefits");
    }

    const getInsurance = (parent, birthParentOrPartner, subHeading) => {
      const { company, network, plan } = parent || {};
      let insurance = "";
      if ((company && report.version === "v1") || !subHeading) {
        insurance = `${company} ${network} ${plan}`;
      } else if (report.version === "v2") {
        if (report.partner.planIds && report.partner.planIds.length > 0) {
          insurance = birthParentOrPartner === "birthParent" ? subHeading[0] : subHeading[1];
        } else if (birthParentOrPartner === "birthParent") {
          insurance = subHeading[0];
        }
      }

      return (
        <Text blue bold small center lineHeightUnits="20px">
          {insurance}
        </Text>
      );
    };

    const removePreference = async (custom) => {
      const token = await getToken();
      const reset = custom === "custom" ? { customPreferenceOne: {} } : { customPreferenceTwo: {} };

      await disablePreference(report._id, custom, token);
      dispatch(Actions.Creators.switchState(reset));
    };

    const expectedServicesTitle = "Expected Services Only";
    const unexpectedServicesTitle = "Expected and Unexpected Services";

    return (
      <>
        <Row
          col
          selectHeader
          style={{ width: isToSelect && "100%", padding: isToSelect && "0px" }}
          className="custom-select"
          customWidth="100%"
        >
          {isToSelect ? (
            <CustomSelect onClick={() => setCustomPrefrenceModal(id)} />
          ) : (
            <div
              style={{
                padding: 20,
                border: "2px solid #D0D5DD",
                borderRadius: "8px",
                position: "relative",
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                width: "100%",
              }}
            >
              {!(id === "custom" || id === "custom2") ? (
                <div style={{ position: "absolute", top: 5, right: 5 }} title="Recommended">
                  <svg
                    style={{ width: "20px", height: "20px", fill: "#FDB022" }}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.007 5.404.433c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.433 2.082-5.006z"
                      clipRule="evenodd"
                    />
                  </svg>
                </div>
              ) : (
                <></>
              )}
              {getInsurance(birthParent, "birthParent", subHeading)}
              {report.type === "household" && getInsurance(birthParent, "partner", subHeading)}
            </div>
          )}
        </Row>
        <br></br>
        <Badge
          count={
            (id === "custom" || id === "custom2") &&
            !isToSelect && (
              <div className="iconContainer2" onClick={() => removePreference(id)}>
                <MinusOutlined style={{ fontSize: 20, color: "white" }} twoToneColor="#ff5252" />
              </div>
            )
          }
        >
          <OptWrapper opacity={isToSelect}>
            <Row color="primary" header>
              <TableCell tall middle headerBgColor padding="6px" bRadius="4px">
                <Text small center headerColor bold>
                  {expectedServicesTitle}
                </Text>
              </TableCell>
              <TableCell tall width="20px" white></TableCell>
              <TableCell tall middle headerBgColor padding="6px" bRadius="4px">
                <Text small center headerColor bold>
                  {unexpectedServicesTitle}
                </Text>
              </TableCell>
            </Row>

            {expected ? (
              <>
                <Collapse isOpened={expand.total}>
                  {preferences["1"].hsaHraImpact === "spending-hsa-hra" && (
                    <>
                      {headerTree.hsaBenefits && (
                        <RecursiveCostTwoColumn items={hsaBenefitsBreakdowns} fullPath={"hsaBenefits"} level={1} />
                      )}
                      {renderCost({
                        type: FieldOptions.OptOutPayment,
                        data: optOutPayments,
                        event: lifeEvent.name,
                      })}

                      <Row color="tertiary" justify="center">
                        {expand.subtotal ? (
                          <></>
                        ) : (
                          <CurrencyCellPair
                            values={[-1 * unexpected.total, -1 * expected.total]}
                            fontSize="14"
                            variation="bold"
                          />
                        )}
                      </Row>
                      <Collapse isOpened={expand.subtotal}>
                        {renderCost({
                          type: getFieldKey({ field: FieldOptions.Premiums }),
                          data: premiumCharges,
                          event: lifeEvent.name,
                        })}
                        {renderCost({
                          type: getFieldKey({ field: FieldOptions.Surcharges }),
                          data: surchargeCharges,
                          event: lifeEvent.name,
                        })}
                        {headerTree.insurance && (
                          <RecursiveCostTwoColumn
                            items={insuranceBreakdowns}
                            fullPath={"insurance"}
                            level={primaryRowsLevel}
                          />
                        )}
                        {renderCost({
                          type: getFieldKey({ field: FieldOptions.OutOfNetwork }),
                          data: outOfNetworkCharges,
                          event: lifeEvent.name,
                        })}
                        {preferences["1"].hsaHraImpact === "spending-only" &&
                          renderCost({
                            type: "HSA",
                            data: hsaCharges,
                            event: lifeEvent.name,
                            period: "Before",
                          })}
                        {renderCost({
                          type: "HRA",
                          data: hraCharges,
                          event: lifeEvent.name,
                          period: "Before",
                        })}
                        {renderCost({
                          type: getFieldKey({ field: FieldOptions.NotCovered }),
                          data: notCovered,
                          event: lifeEvent.name,
                        })}
                        <Row color="tertiary" justify="center">
                          <CurrencyCellPair
                            values={[-1 * unexpected.total, -1 * expected.total]}
                            fontSize="14"
                            variation="bold"
                          />
                        </Row>
                      </Collapse>
                    </>
                  )}
                  {preferences["1"].hsaHraImpact === "spending-only" && (
                    <>
                      {renderCost({
                        type: getFieldKey({ field: FieldOptions.Premiums }),
                        data: premiumCharges,
                        event: lifeEvent.name,
                      })}
                      {renderCost({
                        type: getFieldKey({ field: FieldOptions.Surcharges }),
                        data: surchargeCharges,
                        event: lifeEvent.name,
                      })}
                      {headerTree.insurance && (
                        <RecursiveCostTwoColumn
                          items={insuranceBreakdowns}
                          fullPath={"insurance"}
                          level={primaryRowsLevel}
                        />
                      )}
                      {renderCost({
                        type: getFieldKey({ field: FieldOptions.OutOfNetwork }),
                        data: outOfNetworkCharges,
                        event: lifeEvent.name,
                      })}
                      {renderCost({
                        type: getFieldKey({ field: FieldOptions.OptOutPayment }),
                        data: optOutPayments,
                        event: lifeEvent.name,
                      })}
                      {preferences["1"].hsaHraImpact === "spending-only" &&
                        renderCost({
                          type: "HSA",
                          data: hsaCharges,
                          event: lifeEvent.name,
                          period: "Before",
                        })}
                      {renderCost({
                        type: "HRA",
                        data: hraCharges,
                        event: lifeEvent.name,
                        period: "Before",
                      })}
                      {renderCost({
                        type: getFieldKey({ field: FieldOptions.NotCovered }),
                        data: notCovered,
                        event: lifeEvent.name,
                      })}
                    </>
                  )}
                </Collapse>
                <Row
                  color={expand.total || expand.leftover ? "quaternary" : "tertiary"}
                  bgColor="#E8EEFA"
                  style={{
                    boxShadow: "60px 0px #E8EEFA",
                  }}
                  margin={"5px 0 0 0"}
                >
                  <TableCell tall middle>
                    <Text medium bold center headerColor>
                      {formatCurrency(
                        preferences["1"].hsaHraImpact === "spending-only"
                          ? expected.total
                          : expected.grandTotalWithHsaHraImpact,
                      )}
                    </Text>
                  </TableCell>
                  <TableCell tall padding="0" width="40px" />
                  <TableCell tall middle>
                    <Text medium bold center headerColor>
                      {formatCurrency(
                        preferences["1"].hsaHraImpact === "spending-only"
                          ? unexpected.total
                          : unexpected.grandTotalWithHsaHraImpact,
                      )}{" "}
                    </Text>
                  </TableCell>
                </Row>
                <Collapse isOpened={expand.leftover}>
                  <Row />
                  {preferences["1"].hsaHraImpact === "spending-only" &&
                    renderCost({
                      type: "HSA",
                      data: hsaCharges,
                      event: lifeEvent.name,
                      period: "After",
                    })}
                  {renderCost({
                    type: "HRA",
                    data: hraCharges,
                    event: lifeEvent.name,
                    period: "After",
                  })}
                </Collapse>
              </>
            ) : (
              <Row color="tertiary">
                <TableCell tall middle>
                  <Text black lg bold center>
                    $-
                  </Text>
                </TableCell>
                <TableCell tall padding="0" width="40px">
                  <Icon blackArrow />
                </TableCell>
                <TableCell tall middle>
                  <Text black lg bold center>
                    $-
                  </Text>
                </TableCell>
              </Row>
            )}
          </OptWrapper>
        </Badge>
      </>
    );
  };

  const renderSubItemTitles = ({
    item,
    fontSize,
    category,
    subCategory,
    underline,
    isExpand,
    customTitle,
    level,
    useCustomColor,
    largeText,
  }) => {
    return (
      <Row
        justify="start"
        category={category}
        subCategory={subCategory}
        customHeight={largeText && width < 1636 && "65px"}
      >
        {isExpand ? (
          <Link
            color={colorFromLevel(expand[item] ? level || 0 : -1)}
            bold={expand[item]}
            fontSize={16}
            lHeight={"1rem"}
            justBetween
            noMargin
            onClick={() => handleExpand(item)}
          >
            {customTitle ? customTitle : item}
            <ExpandIcon expanded={expand[item]} />
          </Link>
        ) : (
          <Text
            color={useCustomColor ? colorFromLevel(expand[item] ? level || 0 : -1) : colorFromLevel(-1)}
            fontSize={fontSize}
            underline={underline}
          >
            {customTitle ? customTitle : item}
          </Text>
        )}
      </Row>
    );
  };

  const getSelectedReport = () => {
    const subheadings = [
      customOptimizations[0]?.selected.subHeading[0] || null,
      customOptimizations[0]?.selected.subHeading[1] || null,
    ];

    for (let allOptimization of allOptimizations) {
      if (
        allOptimization.selected.subHeading[0] == subheadings[0] &&
        allOptimization.selected.subHeading[1] == subheadings[1]
      ) {
        return allOptimization.selected;
      }
    }

    return allOptimizations?.[0]?.selected;
  };

  const getFirstCustomPreference = () => {
    const subheadings = [customPreferenceOne?.subHeading?.[0] || null, customPreferenceOne?.subHeading?.[1] || null];

    for (let allOptimization of allOptimizations) {
      if (
        allOptimization.selected.subHeading[0] == subheadings[0] &&
        allOptimization.selected.subHeading[1] == subheadings[1]
      ) {
        return allOptimization.selected;
      }
    }

    return customPreferenceOne;
  };

  const getSecondCustomPreference = () => {
    const subheadings = [customPreferenceTwo?.subHeading?.[0] || null, customPreferenceTwo?.subHeading?.[1] || null];

    for (let allOptimization of allOptimizations) {
      if (
        allOptimization.selected.subHeading[0] == subheadings[0] &&
        allOptimization.selected.subHeading[1] == subheadings[1]
      ) {
        return allOptimization.selected;
      }
    }

    return customPreferenceTwo;
  };

  const mainOpt = getSelectedReport();
  const customOptOne = getFirstCustomPreference();
  const customOptTwo = getSecondCustomPreference();

  const recursiveTrees = addMissingAttributesToBreakdown(mainOpt, customOptOne, customOptTwo, preferences);

  const allZero = (tree) => tree.every((tree) => tree?.total === 0);

  const headerTree = {
    insurance: allZero(recursiveTrees?.allTrees?.insurance) ? null : [...recursiveTrees?.allTrees?.insurance],
    hsaBenefits: allZero(recursiveTrees?.allTrees?.hsaBenefits) ? null : [...recursiveTrees?.allTrees?.hsaBenefits],
  };

  const renderInsuranceSubItemTitles = (company, companyType, level = 0, showHeader = true) => {
    const { insuranceFields = {}, name } = company;
    const { Deductible, Copay, Coinsurance } = insuranceFields;

    const Wrapper = showHeader
      ? ({ children }) => (
          <CustomCollapse level={level} isOpened={isExpanded({ field: FieldOptions.Insurance, companyType })}>
            {children}
          </CustomCollapse>
        )
      : React.Fragment;

    if (Deductible || Copay || Coinsurance) {
      return (
        <>
          {showHeader && (
            <RowItemTitle
              canExpand
              field={FieldOptions.Insurance}
              companyType={companyType}
              level={level}
              rowType="category"
            >
              {name}
            </RowItemTitle>
          )}

          <Wrapper>
            {Deductible && (
              <Row justify="start" subCategory>
                <Text grey fontSize="16" bold>
                  Deductible
                </Text>
              </Row>
            )}
            {Copay && (
              <Row justify="start" subCategory>
                <Text grey fontSize="16" bold>
                  Copay
                </Text>
              </Row>
            )}
            {Coinsurance && (
              <Row justify="start" subCategory>
                <Text grey fontSize="16" bold>
                  Coinsurance
                </Text>
              </Row>
            )}
            {showHeader && (
              <RowItemTitle field={FieldOptions.Insurance} companyType={companyType} level={level} rowType="category">
                Total
              </RowItemTitle>
            )}
          </Wrapper>
        </>
      );
    }
  };

  const { companyA, companyB } = showSubRows;

  const isPremiumsAfter =
    companyA[getFieldKey({ field: FieldOptions.Premiums, period: PeriodOptions.After })] ||
    companyB[getFieldKey({ field: FieldOptions.Premiums, period: PeriodOptions.After })]; // are there any Premium After

  const isSurchargesAfter =
    companyA[getFieldKey({ field: FieldOptions.Surcharges, period: PeriodOptions.After })] ||
    companyB[getFieldKey({ field: FieldOptions.Surcharges, period: PeriodOptions.After })];

  const isOptOutPaymentAfter = companyA["Opt-Out Payment After"] || companyB["Opt-Out Payment After"];

  const withSpousalSurcharges =
    companyA["Company Details A"] ||
    companyA["Company Details B"] ||
    companyB["Company Details A"] ||
    companyB["Company Details B"];

  const RenderByCompany = ({ options, unique, multiple }) => {
    if (!options?.companyA?.name || !options?.companyB?.name) {
      return unique;
    } else {
      return multiple;
    }
  };

  return (
    <Wrapper>
      <HideMobile>
        <Header title="How much could I be expected to pay overall?" bigTitle />
      </HideMobile>
      <FilterSortButton toggleSideBar={toggleSideBar} toggleAssumptions={toggleAssumptions} />
      <FlexWrapper hide={!report} justify="flex-start" align="start">
        <InnerWrapper header align="flex-end">
          <Row customHeight="135px">{/*Plan Name*/}</Row>
          <br></br>
          <Row padding={"4px 10px;"} labelsHeader />

          <Collapse isOpened={expand.total} initialStyle={{ padding: "0 0 0 10px" }}>
            {preferences["1"].hsaHraImpact === "spending-hsa-hra" && (
              <>
                <RecursiveHeader items={headerTree?.hsaBenefits} fullPath={"hsaBenefits"} level={1} />
                <Collapse isOpened={showRows[getFieldKey({ field: FieldOptions.OptOutPayment })]}>
                  <RowItemTitle level={1} canExpand field={FieldOptions.OptOutPayment}>
                    Opt-Out Payment
                  </RowItemTitle>

                  <CustomCollapse level="1" isOpened={isExpanded({ field: FieldOptions.OptOutPayment })}>
                    {isOptOutPaymentAfter && (
                      <Row justify="start" category>
                        <Link
                          color={colorFromLevel(
                            isExpanded({ field: FieldOptions.OptOutPayment, period: PeriodOptions.Before }) ? 2 : -1,
                          )}
                          bold
                          fontSize={16}
                          lHeight={"1rem"}
                          justBetween
                          onClick={() =>
                            handleExpand(
                              getFieldKey({ field: FieldOptions.OptOutPayment, period: PeriodOptions.Before }),
                            )
                          }
                        >
                          Before Baby
                          <ExpandIcon
                            expanded={isExpanded({ field: FieldOptions.OptOutPayment, period: PeriodOptions.Before })}
                          />
                        </Link>
                      </Row>
                    )}
                    <CustomCollapse
                      level="2"
                      isOpened={
                        isExpanded({ field: FieldOptions.OptOutPayment, period: PeriodOptions.Before }) ||
                        !isOptOutPaymentAfter
                      }
                    >
                      {companyA[getFieldKey({ field: FieldOptions.OptOutPayment, period: PeriodOptions.Before })] && (
                        <Row justify="start" category={!isOptOutPaymentAfter} subCategory={isOptOutPaymentAfter}>
                          <Text grey fontSize="16" bold>
                            {companyA.name}
                          </Text>
                        </Row>
                      )}
                      {companyB[getFieldKey({ field: FieldOptions.OptOutPayment, period: PeriodOptions.Before })] && (
                        <Row justify="start" category={!isOptOutPaymentAfter} subCategory={isOptOutPaymentAfter}>
                          <Text grey fontSize="16" bold>
                            {companyB.name}
                          </Text>
                        </Row>
                      )}
                      {isOptOutPaymentAfter && (
                        <Row justify="start" category>
                          <Text
                            color={colorFromLevel(
                              isExpanded({ field: FieldOptions.OptOutPayment, period: PeriodOptions.Before }) ? 2 : -1,
                            )}
                            bold
                            fontSize={16}
                            lHeight={"1rem"}
                            justStart
                          >
                            Total
                          </Text>
                        </Row>
                      )}
                    </CustomCollapse>

                    {isOptOutPaymentAfter && (
                      <>
                        <Row justify="start" category>
                          <Link
                            color={colorFromLevel(expand["Opt-Out Payment After"] ? 2 : -1)}
                            bold
                            fontSize={16}
                            lHeight={"1rem"}
                            justBetween
                            onClick={() => handleExpand("Opt-Out Payment After")}
                          >
                            After Baby
                            <ExpandIcon expanded={isExpanded({ field: FieldOptions.OptOutPayment, period: "After" })} />
                          </Link>
                        </Row>
                        <CustomCollapse level="2" isOpened={expand["Opt-Out Payment After"]}>
                          {companyA["Opt-Out Payment After"] && (
                            <Row justify="start" subCategory>
                              <Text grey fontSize="16" bold>
                                {companyA.name}
                              </Text>
                            </Row>
                          )}
                          {companyB["Opt-Out Payment After"] && (
                            <Row justify="start" subCategory>
                              <Text grey fontSize="16" bold>
                                {companyB.name}
                              </Text>
                            </Row>
                          )}
                          <Row justify="start" category>
                            <Text
                              color={colorFromLevel(expand["Opt-Out Payment After"] ? 2 : -1)}
                              bold
                              fontSize={16}
                              lHeight={"1rem"}
                              justStart
                            >
                              Total
                            </Text>
                          </Row>
                        </CustomCollapse>
                      </>
                    )}

                    <RowItemTitle level={1} field={FieldOptions.OptOutPayment}>
                      Total
                    </RowItemTitle>
                  </CustomCollapse>
                </Collapse>
              </>
            )}
            {preferences["1"].hsaHraImpact === "spending-hsa-hra" && (
              <Row justify="start" customWidth="100%">
                <Link
                  color={colorFromLevel(expand["subtotal"] ? 1 : -1)}
                  bold
                  fontSize={16}
                  lHeight={"1rem"}
                  justBetween
                  onClick={() => handleExpand("subtotal")}
                >
                  Healthcare Spending
                  <ExpandIcon expanded={isExpanded({ field: FieldOptions.subtotal })} />
                </Link>
              </Row>
            )}
            <CustomCollapse
              level="1"
              isOpened={preferences["1"].hsaHraImpact === "spending-hsa-hra" ? expand.subtotal : true}
              hideSeparator={preferences["1"]?.hsaHraImpact !== "spending-hsa-hra"}
            >
              <Collapse isOpened={showRows?.[FieldOptions.Premiums]}>
                <RowItemTitle level={2} canExpand field={getFieldKey({ field: FieldOptions.Premiums })}>
                  Premiums
                </RowItemTitle>

                <CustomCollapse level="2" isOpened={isExpanded({ field: FieldOptions.Premiums })}>
                  {isPremiumsAfter && (
                    <Row justify="start" category>
                      <Link
                        color={colorFromLevel(
                          isExpanded({ field: FieldOptions.Premiums, period: PeriodOptions.Before }) ? 3 : -1,
                        )}
                        bold
                        fontSize={16}
                        lHeight={"1rem"}
                        justBetween
                        onClick={() =>
                          handleExpand(getFieldKey({ field: FieldOptions.Premiums, period: PeriodOptions.Before }))
                        }
                      >
                        Before Baby
                        <ExpandIcon
                          expanded={isExpanded({ field: FieldOptions.Premiums, period: PeriodOptions.Before })}
                        />
                      </Link>
                    </Row>
                  )}
                  <CustomCollapse
                    level="3"
                    isOpened={
                      isExpanded({ field: FieldOptions.Premiums, period: PeriodOptions.Before }) || !isPremiumsAfter
                    }
                  >
                    {companyA[getFieldKey({ field: FieldOptions.Premiums, period: PeriodOptions.Before })] && (
                      <>
                        <Row justify="start" category={!isPremiumsAfter} subCategory={isPremiumsAfter}>
                          {withSpousalSurcharges ? (
                            <Link
                              color={colorFromLevel(expand["Company Details A"] ? 4 : -1)}
                              bold
                              fontSize={16}
                              lHeight={"1rem"}
                              justBetween
                              underline
                              onClick={() => handleExpand("Company Details A")}
                            >
                              {companyA.name}
                              <ExpandIcon expanded={expand["Company Details A"]} />
                            </Link>
                          ) : (
                            <Text grey fontSize="16" bold>
                              {companyA.name}
                            </Text>
                          )}
                        </Row>
                        <CustomCollapse level="4" isOpened={expand["Company Details A"]}>
                          <Row justify="start" category={!isPremiumsAfter} subCategory={isPremiumsAfter}>
                            <Text fontSize="16" bold>
                              Premium
                            </Text>
                          </Row>
                          <Row justify="start" category={!isPremiumsAfter} subCategory={isPremiumsAfter}>
                            <Text fontSize="16" bold>
                              Spousal Surcharge
                            </Text>
                          </Row>
                          <Row justify="start" category={!isPremiumsAfter} subCategory={isPremiumsAfter}>
                            {withSpousalSurcharges && (
                              <Text
                                color={colorFromLevel(expand["Company Details A"] ? 4 : -1)}
                                bold
                                fontSize={16}
                                lHeight={"1rem"}
                                justStart
                                underline
                              >
                                Total
                              </Text>
                            )}
                          </Row>
                        </CustomCollapse>
                      </>
                    )}

                    {companyB[getFieldKey({ field: FieldOptions.Premiums, period: PeriodOptions.Before })] && (
                      <>
                        <Row justify="start" category={!isPremiumsAfter} subCategory={isPremiumsAfter}>
                          {withSpousalSurcharges ? (
                            <Link
                              color={colorFromLevel(expand["Company Details B"] ? 4 : -1)}
                              bold
                              fontSize={16}
                              lHeight={"1rem"}
                              justBetween
                              underline
                              onClick={() => handleExpand("Company Details B")}
                            >
                              {companyB.name}
                              <ExpandIcon expanded={expand["Company Details B"]} />
                            </Link>
                          ) : (
                            <Text grey fontSize="16" bold>
                              {companyB.name}
                            </Text>
                          )}
                        </Row>
                        <CustomCollapse level="4" isOpened={expand["Company Details B"]}>
                          <Row justify="start" category={!isPremiumsAfter} subCategory={isPremiumsAfter}>
                            <Text fontSize="16" bold>
                              Premium
                            </Text>
                          </Row>
                          <Row justify="start" category={!isPremiumsAfter} subCategory={isPremiumsAfter}>
                            <Text fontSize="16" bold>
                              Spousal Surcharge
                            </Text>
                          </Row>
                          <Row justify="start" category={!isPremiumsAfter} subCategory={isPremiumsAfter}>
                            {withSpousalSurcharges && (
                              <Text
                                color={colorFromLevel(expand["Company Details B"] ? 4 : -1)}
                                bold
                                fontSize={16}
                                lHeight={"1rem"}
                                justStart
                                underline
                              >
                                Total
                              </Text>
                            )}
                          </Row>
                        </CustomCollapse>
                      </>
                    )}
                    {isPremiumsAfter && (
                      <Row justify="start" category>
                        <Text
                          color={colorFromLevel(
                            isExpanded({ field: FieldOptions.Premiums, period: PeriodOptions.Before }) ? 3 : -1,
                          )}
                          bold
                          fontSize={16}
                          lHeight={"1rem"}
                          justStart
                        >
                          Total
                        </Text>
                      </Row>
                    )}
                  </CustomCollapse>

                  {isPremiumsAfter && (
                    <>
                      <Row justify="start" category>
                        <Link
                          color={colorFromLevel(
                            isExpanded({ field: FieldOptions.Premiums, period: PeriodOptions.After }) ? 3 : -1,
                          )}
                          bold
                          fontSize={16}
                          lHeight={"1rem"}
                          justBetween
                          onClick={() =>
                            handleExpand(getFieldKey({ field: FieldOptions.Premiums, period: PeriodOptions.After }))
                          }
                        >
                          After Baby
                          <ExpandIcon
                            expanded={isExpanded({ field: FieldOptions.Premiums, period: PeriodOptions.After })}
                          />
                        </Link>
                      </Row>
                      <CustomCollapse
                        level="3"
                        isOpened={isExpanded({ field: FieldOptions.Premiums, period: PeriodOptions.After })}
                      >
                        {companyA[getFieldKey({ field: FieldOptions.Premiums, period: PeriodOptions.After })] && (
                          <Row justify="start" subCategory>
                            <Text grey fontSize="16" bold>
                              {companyA.name}
                            </Text>
                          </Row>
                        )}
                        {companyB[getFieldKey({ field: FieldOptions.Premiums, period: PeriodOptions.After })] && (
                          <Row justify="start" subCategory>
                            <Text grey fontSize="16" bold>
                              {companyB.name}
                            </Text>
                          </Row>
                        )}
                        <Row justify="start" category>
                          <Text
                            color={colorFromLevel(
                              expand[getFieldKey({ field: FieldOptions.Premiums, period: PeriodOptions.After })]
                                ? 3
                                : -1,
                            )}
                            bold
                            fontSize={16}
                            lHeight={"1rem"}
                            justStart
                          >
                            Total
                          </Text>
                        </Row>
                      </CustomCollapse>
                    </>
                  )}

                  <RowItemTitle level={2} field={getFieldKey({ field: FieldOptions.Premiums })}>
                    Total
                  </RowItemTitle>
                </CustomCollapse>
              </Collapse>

              <Collapse isOpened={showRows[FieldOptions.Surcharges]}>
                <RowItemTitle level={2} canExpand field={getFieldKey({ field: FieldOptions.Surcharges })}>
                  Surcharges
                </RowItemTitle>
                <CustomCollapse level="2" isOpened={isExpanded({ field: FieldOptions.Surcharges })}>
                  {isSurchargesAfter && (
                    <Row justify="start" category>
                      <Link
                        color={colorFromLevel(
                          isExpanded({ field: FieldOptions.Surcharges, period: PeriodOptions.Before }) ? 3 : -1,
                        )}
                        bold
                        fontSize={16}
                        lHeight={"1rem"}
                        justBetween
                        onClick={() =>
                          handleExpand(getFieldKey({ field: FieldOptions.Surcharges, period: PeriodOptions.Before }))
                        }
                      >
                        Before Baby
                        <ExpandIcon
                          expanded={isExpanded({ field: FieldOptions.Surcharges, period: PeriodOptions.Before })}
                        />
                      </Link>
                    </Row>
                  )}
                  <CustomCollapse
                    level="3"
                    isOpened={
                      isExpanded({ field: FieldOptions.Surcharges, period: PeriodOptions.Before }) || !isSurchargesAfter
                    }
                  >
                    {companyA[getFieldKey({ field: FieldOptions.Surcharges, period: PeriodOptions.Before })] && (
                      <Row justify="start" category={!isSurchargesAfter} subCategory={isSurchargesAfter}>
                        <Text grey fontSize="16" bold>
                          {companyA.name}
                        </Text>
                      </Row>
                    )}
                    {companyB[getFieldKey({ field: FieldOptions.Surcharges, period: PeriodOptions.Before })] && (
                      <Row justify="start" category={!isSurchargesAfter} subCategory={isSurchargesAfter}>
                        <Text grey fontSize="16" bold>
                          {companyB.name}
                        </Text>
                      </Row>
                    )}
                    {isSurchargesAfter && (
                      <Row justify="start" category>
                        <Text
                          color={colorFromLevel(
                            isExpanded({ field: FieldOptions.Surcharges, period: PeriodOptions.Before }) ? 3 : -1,
                          )}
                          bold
                          fontSize={16}
                          lHeight={"1rem"}
                          justStart
                        >
                          Total
                        </Text>
                      </Row>
                    )}
                  </CustomCollapse>

                  {isSurchargesAfter && (
                    <>
                      <Row justify="start" category>
                        <Link
                          color={colorFromLevel(
                            isExpanded({ field: FieldOptions.Surcharges, period: PeriodOptions.After }) ? 3 : -1,
                          )}
                          bold
                          fontSize={16}
                          lHeight={"1rem"}
                          justBetween
                          onClick={() =>
                            handleExpand(getFieldKey({ field: FieldOptions.Surcharges, period: PeriodOptions.After }))
                          }
                        >
                          After Baby
                          <ExpandIcon
                            expanded={isExpanded({ field: FieldOptions.Surcharges, period: PeriodOptions.After })}
                          />
                        </Link>
                      </Row>
                      <CustomCollapse
                        level="3"
                        isOpened={isExpanded({ field: FieldOptions.Surcharges, period: PeriodOptions.After })}
                      >
                        {companyA[getFieldKey({ field: FieldOptions.Surcharges, period: PeriodOptions.After })] && (
                          <Row justify="start" subCategory>
                            <Text grey fontSize="16" bold>
                              {companyA.name}
                            </Text>
                          </Row>
                        )}
                        {companyB[getFieldKey({ field: FieldOptions.Surcharges, period: PeriodOptions.After })] && (
                          <Row justify="start" subCategory>
                            <Text grey fontSize="16" bold>
                              {companyB.name}
                            </Text>
                          </Row>
                        )}
                        <Row justify="start" category>
                          <Text
                            color={colorFromLevel(
                              isExpanded({ field: FieldOptions.Surcharges, period: PeriodOptions.After }) ? 3 : -1,
                            )}
                            bold
                            fontSize={16}
                            lHeight={"1rem"}
                            justStart
                          >
                            Total
                          </Text>
                        </Row>
                      </CustomCollapse>
                    </>
                  )}

                  <RowItemTitle level={2} field={FieldOptions.Surcharges}>
                    Total
                  </RowItemTitle>
                </CustomCollapse>
              </Collapse>

              <RecursiveHeader items={headerTree?.insurance} fullPath={"insurance"} level={primaryRowsLevel} />

              <>
                <Collapse isOpened={showRows[getFieldKey({ field: FieldOptions.OutOfNetwork })]}>
                  <RowItemTitle level={2} canExpand field={getFieldKey({ field: FieldOptions.OutOfNetwork })}>
                    Out-Of-Network Charges
                  </RowItemTitle>
                  <CustomCollapse level="2" isOpened={isExpanded({ field: FieldOptions.OutOfNetwork })}>
                    {renderSubItemTitles({
                      item: "Cost of Visits",
                      fontSize: "14",
                      category: true,
                    })}

                    {renderSubItemTitles({
                      item: "Reimbursement from Insurance",
                      fontSize: "14",
                      category: true,
                      isExpand: true,
                      level: 3,
                    })}
                    <CustomCollapse level="3" isOpened={expand["Reimbursement from Insurance"]}>
                      {showSubRows[getFieldKey({ field: FieldOptions.OutOfNetwork })]["Negotiated Rate"] &&
                        renderSubItemTitles({
                          item: "Negotiated Rate",
                          fontSize: "16",
                          subCategory: true,
                        })}

                      {showSubRows[getFieldKey({ field: FieldOptions.OutOfNetwork })]["Out-of-Pocket Accrual"] &&
                        renderSubItemTitles({
                          item: "Out-of-Pocket Accrual",
                          fontSize: "16",
                          subCategory: true,
                          isExpand: true,
                          level: 4,
                        })}
                      <CustomCollapse level="4" isOpened={expand["Out-of-Pocket Accrual"]}>
                        {showSubRows[getFieldKey({ field: FieldOptions.OutOfNetwork })]["Deductibles"] &&
                          renderSubItemTitles({
                            item: "Deductibles",
                            fontSize: "10",
                            subCategory: true,
                          })}
                        {showSubRows[getFieldKey({ field: FieldOptions.OutOfNetwork })]["Coinsurance"] &&
                          renderSubItemTitles({
                            item: "Coinsurance",
                            fontSize: "10",
                            subCategory: true,
                          })}
                        {showSubRows[getFieldKey({ field: FieldOptions.OutOfNetwork })]["Out-of-Pocket Accrual"] &&
                          renderSubItemTitles({
                            item: "Out-of-Pocket Accrual",
                            fontSize: "16",
                            subCategory: true,
                            level: 4,
                            customTitle: "Total",
                            useCustomColor: true,
                          })}
                      </CustomCollapse>
                      {renderSubItemTitles({
                        item: "Reimbursement from Insurance",
                        fontSize: "14",
                        category: true,
                        level: 3,
                        customTitle: "Total",
                        useCustomColor: true,
                      })}
                    </CustomCollapse>
                    {/* <Row justify="start">
                      <Text
                        color={colorFromLevel(isExpanded({ field: FIELD_OPTIONS["Out-Of-Network"] }) ? 2 : -1)}
                        bold
                        fontSize={16}
                        lHeight={"1rem"}
                        justStart
                      >
                        Total
                      </Text>
                    </Row> */}
                    <RowItemTitle level={2} field={getFieldKey({ field: FieldOptions.OutOfNetwork })}>
                      Total
                    </RowItemTitle>
                  </CustomCollapse>
                </Collapse>
                {preferences["1"].hsaHraImpact === "spending-only" && (
                  <Collapse isOpened={showRows[getFieldKey({ field: FieldOptions.OptOutPayment })]}>
                    <RowItemTitle level={2} canExpand field={FieldOptions.OptOutPayment}>
                      Opt-Out Payment
                    </RowItemTitle>
                    <CustomCollapse level="2" isOpened={isExpanded({ field: FieldOptions.OptOutPayment })}>
                      {isOptOutPaymentAfter && (
                        <Row justify="start" category>
                          <Link
                            color={colorFromLevel(
                              isExpanded({ field: FieldOptions.OptOutPayment, period: PeriodOptions.Before }) ? 3 : -1,
                            )}
                            bold
                            fontSize={16}
                            lHeight={"1rem"}
                            justBetween
                            onClick={() => handleExpand("Opt-Out Payment Before")}
                          >
                            Before Baby
                            <ExpandIcon
                              expanded={isExpanded({ field: FieldOptions.OptOutPayment, period: PeriodOptions.Before })}
                            />
                          </Link>
                        </Row>
                      )}
                      <CustomCollapse
                        level="3"
                        isOpened={
                          isExpanded({ field: FieldOptions.OptOutPayment, period: PeriodOptions.Before }) ||
                          !isOptOutPaymentAfter
                        }
                      >
                        {companyA["Opt-Out Payment Before"] && (
                          <Row justify="start" category={!isOptOutPaymentAfter} subCategory={isOptOutPaymentAfter}>
                            <Text grey fontSize="16" bold>
                              {companyA.name}
                            </Text>
                          </Row>
                        )}
                        {companyB["Opt-Out Payment Before"] && (
                          <Row justify="start" category={!isOptOutPaymentAfter} subCategory={isOptOutPaymentAfter}>
                            <Text grey fontSize="16" bold>
                              {companyB.name}
                            </Text>
                          </Row>
                        )}
                        {isOptOutPaymentAfter && (
                          <Row justify="start" category>
                            <Text
                              color={colorFromLevel(expand["Opt-Out Payment Before"] ? 3 : -1)}
                              bold
                              fontSize={16}
                              lHeight={"1rem"}
                              justStart
                            >
                              Total
                            </Text>
                          </Row>
                        )}
                      </CustomCollapse>

                      {isOptOutPaymentAfter && (
                        <>
                          <Row justify="start" category>
                            <Link
                              color={colorFromLevel(expand["Opt-Out Payment After"] ? 3 : -1)}
                              bold
                              fontSize={16}
                              lHeight={"1rem"}
                              justBetween
                              onClick={() => handleExpand("Opt-Out Payment After")}
                            >
                              After Baby
                              <ExpandIcon
                                expanded={isExpanded({
                                  field: FieldOptions.OptOutPayment,
                                  period: PeriodOptions.After,
                                })}
                              />
                            </Link>
                          </Row>
                          <CustomCollapse level="3" isOpened={expand["Opt-Out Payment After"]}>
                            {companyA["Opt-Out Payment After"] && (
                              <Row justify="start" subCategory>
                                <Text grey fontSize="16" bold>
                                  {companyA.name}
                                </Text>
                              </Row>
                            )}
                            {companyB["Opt-Out Payment After"] && (
                              <Row justify="start" subCategory>
                                <Text grey fontSize="16" bold>
                                  {companyB.name}
                                </Text>
                              </Row>
                            )}
                            <Row justify="start" category>
                              <Text
                                color={colorFromLevel(expand["Opt-Out Payment After"] ? 3 : -1)}
                                bold
                                fontSize={16}
                                lHeight={"1rem"}
                                justStart
                              >
                                Total
                              </Text>
                            </Row>
                          </CustomCollapse>
                        </>
                      )}

                      <RowItemTitle level={2} field={FieldOptions.OptOutPayment}>
                        Total
                      </RowItemTitle>
                    </CustomCollapse>
                  </Collapse>
                )}
                {preferences["1"].hsaHraImpact === "spending-only" && (
                  <Collapse isOpened={showRows["HSA Before"]}>
                    <Collapse isOpened={showRows["HSA Before"]}>
                      <Row justify="start" style={{ fontSize: "16px" }}>
                        HSA
                      </Row>
                    </Collapse>
                  </Collapse>
                )}

                <Collapse isOpened={showRows["HRA Before"]}>
                  <Row justify="start" style={{ fontSize: "16px" }}>
                    HRA
                  </Row>
                </Collapse>
              </>

              <Collapse isOpened={showRows[getFieldKey({ field: FieldOptions.NotCovered })]}>
                <RowItemTitle level={2} canExpand field={getFieldKey({ field: FieldOptions.NotCovered })}>
                  Not Covered
                </RowItemTitle>
                <CustomCollapse level="2" isOpened={isExpanded({ field: FieldOptions.NotCovered })}>
                  {companyA[getFieldKey({ field: FieldOptions.NotCovered })] &&
                    renderSubItemTitles({
                      item: companyA.name,
                      fontSize: "14",
                      category: true,
                    })}
                  {companyB[getFieldKey({ field: FieldOptions.NotCovered })] &&
                    renderSubItemTitles({
                      item: companyB.name,
                      fontSize: "14",
                      category: true,
                    })}

                  <RowItemTitle level={2} field={getFieldKey({ field: FieldOptions.NotCovered })}>
                    Total
                  </RowItemTitle>
                </CustomCollapse>
              </Collapse>
              {preferences["1"].hsaHraImpact === "spending-hsa-hra" && (
                <RowItemTitle level={1} field={FieldOptions.subtotal}>
                  Total
                </RowItemTitle>
              )}
            </CustomCollapse>
          </Collapse>

          <Row justify="start" align="center" bgColor="#E8EEFA" padding={"4px 10px;"} margin={"5px 0 0 0"}>
            <Link bold blue textAlign justBetween fontSize={20} onClick={() => handleExpand("total")}>
              Estimated Grand Total
              <ExpandIcon expanded={isExpanded({ field: FieldOptions.total })} />
            </Link>
          </Row>

          <Collapse isOpened={true} initialStyle={{ padding: "0 0 0 10px" }}>
            {preferences["1"].hsaHraImpact === "spending-only" && (showRows["HSA After"] || showRows["HRA After"]) && (
              <>
                <RowItemTitle level={1} canExpand field={FieldOptions.leftover}>
                  Remaining HSA/HRA breakdown
                </RowItemTitle>
                <CustomCollapse level="1" isOpened={expand.leftover}>
                  <Collapse isOpened={showRows["HSA After"]}>
                    <Row justify="start" style={{ fontSize: "16px" }}>
                      <Text fontSize={16} lHeight={"1rem"} justStart>
                        Remaining HSA
                      </Text>
                    </Row>
                  </Collapse>

                  <Collapse isOpened={showRows["HRA After"]}>
                    <Row justify="start" style={{ fontSize: "16px" }}>
                      <Text fontSize={16} lHeight={"1rem"} justStart>
                        Remaining HRA
                      </Text>
                    </Row>
                  </Collapse>
                </CustomCollapse>
              </>
            )}
          </Collapse>
        </InnerWrapper>
        <CardsWrapper>
          <InnerWrapper card width="100%">
            {renderRisk(mainOpt, recursiveTrees.main, options.primary, headerTree)}
          </InnerWrapper>
          <Text black bold italic fontSize="20" margin="50px 10px">
            vs
          </Text>
          <InnerWrapper card width="100%">
            {renderRisk(customOptOne, recursiveTrees.customOne, "custom", headerTree)}
          </InnerWrapper>
          <Text black bold italic fontSize="20" margin="50px 10px">
            vs
          </Text>
          <InnerWrapper card width="100%">
            {renderRisk(customOptTwo, recursiveTrees.customTwo, "custom2", headerTree)}
          </InnerWrapper>
        </CardsWrapper>
      </FlexWrapper>
      <OptimizeModal
        report={report}
        birthParentPlans={birthParentPlans}
        partnerPlans={partnerPlans}
        show={customPrefrenceModal}
        close={() => setCustomPrefrenceModal(!customPrefrenceModal)}
        handleUpdate={updatePreference}
      />
    </Wrapper>
  );
};

const WithContext = (props) => {
  return (
    <WhatIfStateProvider
      report={props.report}
      birthParentPlans={props.birthParentPlans}
      partnerPlans={props.partnerPlans}
    >
      <RecursiveContextProvider>
        <WhatIfV2 {...props} />
      </RecursiveContextProvider>
    </WhatIfStateProvider>
  );
};

export default WithContext;
