export const formatCurrency = (value) => {
  if (value == null) return "...";
  if (typeof value !== "number") return value;
  return Math.round(value || 0).toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });
};

export const formatterNumber = (value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

export const parserNumber = (value) => value?.replace(/\$\s?|(,*)/g, "");
