import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Actions from "store/report/actions";
import { RadioGroup, booleanToTextValue, textValueToBoolean } from "../components/RadioGroup";

export const ExpectedServicesFilter = ({ report }) => {
  const dispatch = useDispatch();
  const serviceOption = useSelector((state) => state.report.serviceOption);

  if (report.version === "v1") return null;
  return (
    <RadioGroup
      name="sort-by"
      label="Healthcare Assumptions"
      options={[
        {
          value: booleanToTextValue(true),
          label: "Expected Services Only",
          description: "Only planned visits. These can be changed in the Assumptions area.",
        },
        {
          value: booleanToTextValue(false),
          label: "Expected and Unexpected Services",
          description:
            "Making sure I'm covered if something bad happens. These can be changed in the Assumptions area.",
        },
      ]}
      onChange={(value) => {
        console.log("onChangeValue", value);

        dispatch(
          Actions.Creators.switchState({
            serviceOption: textValueToBoolean(value),
          }),
        );
      }}
      value={booleanToTextValue(serviceOption)}
      rightAligned
    />
  );
};
