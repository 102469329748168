import React, { useState, createContext, useContext } from "react";
import { Link, Text, Tooltip } from "components";
import { formatCurrency } from "utils/currency";
import { CustomCollapse, ExpandIcon } from "./components";
import { colorFromLevel, Row } from "./styles";
import TableCell from "components/atoms/TableCell";
import { cloneDeep } from "lodash";
import { useWindowDimensions } from "utils/window";

export const mockData = {
  title: "In-Network Healthcare Charges",
  childrenKeys: ["deductible", "copay", "coinsurance"],
  children: {
    deductible: {
      title: "Deductible",
      childrenKeys: ["partner", "birthParent"],
      children: {
        partner: {
          title: "Spouse's Deductible",
          childrenKeys: ["Fertility Services (IVF, Egg Freezing)", "Urgent Care", "Labwork"],
          children: {
            "Fertility Services (IVF, Egg Freezing)": {
              title: "Fertility Services (IVF, Egg Freezing)",
              total: 1000,
            },
            "Urgent Care": {
              title: "Urgent Care",
              total: 0,
            },
            Labwork: {
              title: "Labwork",
              total: 0,
            },
          },
          total: 1000,
        },
        birthParent: {
          title: "My Deductible",
          childrenKeys: ["Urgent Care"],
          children: {
            "Urgent Care": {
              title: "Urgent Care",
              total: 0,
            },
          },
          total: 0,
        },
      },
      total: 1000,
    },
    copay: {
      title: "Copay",
      childrenKeys: ["partner", "birthParent"],
      children: {
        partner: {
          title: "Spouse's Copay",
          childrenKeys: ["Fertility Services (IVF, Egg Freezing)", "Urgent Care", "Labwork"],
          children: {
            "Fertility Services (IVF, Egg Freezing)": {
              title: "Fertility Services (IVF, Egg Freezing)",
              total: 0,
            },
            "Urgent Care": {
              title: "Urgent Care",
              total: 0,
            },
            Labwork: {
              title: "Labwork",
              total: 0,
            },
          },
          total: 0,
        },
        birthParent: {
          title: "My Copay",
          childrenKeys: ["Urgent Care"],
          children: {
            "Urgent Care": {
              title: "Urgent Care",
              total: 100,
            },
          },
          total: 100,
        },
      },
      total: 100,
    },
    coinsurance: {
      title: "Coinsurance",
      childrenKeys: ["partner", "birthParent"],
      children: {
        partner: {
          title: "Spouse's Coinsurance",
          childrenKeys: ["Fertility Services (IVF, Egg Freezing)", "Urgent Care", "Labwork"],
          children: {
            "Fertility Services (IVF, Egg Freezing)": {
              title: "Fertility Services (IVF, Egg Freezing)",
              total: 3000,
            },
            "Urgent Care": {
              title: "Urgent Care",
              total: 0,
            },
            Labwork: {
              title: "Labwork",
              total: 0,
            },
          },
          total: 3000,
        },
        birthParent: {
          title: "My Coinsurance",
          childrenKeys: ["Urgent Care"],
          children: {
            "Urgent Care": {
              title: "Urgent Care",
              total: 0,
            },
          },
          total: 0,
        },
      },
      total: 3000,
    },
  },
  total: 4100,
};

const defaultTotalValues = {
  Individual: "n/a",
  Family: "n/a",
  "Large Family": "n/a",
  Enrollment: "n/a",
};

const titlesToCopyTotalValue = ["Tax Rate", "Investment Performance"];

/**
 * Creates an empty node with specified title and total value.
 * - If the title is in `titlesToCopyTotalValue`, the provided total value is used.
 * - If the title is in `defaultTotalValues`, the corresponding value from the map is used.
 * - Otherwise, the total is set to 0.
 *
 * @param {string} title - The title of the node.
 * @param {any} total - The total value to use if the title is in `titlesToCopyTotalValue`.
 * @returns {object} The created node object.
 */
const createEmptyNode = (title, total) => {
  const totalValue = titlesToCopyTotalValue.includes(title) ? total : defaultTotalValues[title] || 0;

  return {
    title,
    childrenKeys: [],
    children: {},
    total: totalValue,
  };
};

const recursiveFillTree = (listOfTrees) => {
  const setOfChildren = [...new Set(listOfTrees?.map((c) => c.childrenKeys).flat(1))];
  if (setOfChildren.filter((item) => item != null).length === 0) return;
  setOfChildren.forEach((key) => {
    const subTrees = listOfTrees?.map((tree) => {
      if (tree?.children?.[key] == null) {
        const existingTreeWithSameKey = listOfTrees.find((tree) => tree.childrenKeys?.includes(key));
        const newSubTree = createEmptyNode(
          existingTreeWithSameKey?.children[key]?.title,
          existingTreeWithSameKey?.children[key]?.total,
        );
        if (!tree.children) tree.children = {};
        if (!tree.childrenKeys) tree.childrenKeys = [];
        tree.children[key] = newSubTree;
        tree.childrenKeys.push(key);
      }
      return tree.children[key];
    });
    recursiveFillTree(subTrees);
  });
};

export const pruneBeforeFill = (listOfTrees, numberOfTrees) => {
  const setOfChildren = [...new Set(listOfTrees?.map((c) => c.childrenKeys).flat(1))];

  setOfChildren.forEach((key) => {
    const subTrees = listOfTrees?.map((tree) => tree?.children?.[key])?.filter(Boolean);

    if (key === "wholeDeductible" && subTrees.length === numberOfTrees) {
      listOfTrees.forEach((tree) => {
        if (tree?.children?.["remainingDeductible"] == null) return;
        delete tree.children["remainingDeductible"];
        tree.childrenKeys = tree.childrenKeys.filter((item) => item !== "remainingDeductible");
      });
    } else if (key === "wholeDeductible" && subTrees.length !== numberOfTrees) {
      listOfTrees.forEach((tree) => {
        if (tree?.children?.[key] == null) return;
        delete tree.children[key];
        tree.childrenKeys = tree.childrenKeys.filter((item) => item !== key);
      });
    }

    pruneBeforeFill(subTrees, numberOfTrees);
  });
};

export const pruneAfterFill = (listOfTrees) => {
  const setOfChildren = [...new Set(listOfTrees?.map((c) => c.childrenKeys).flat(1))];

  setOfChildren.forEach((key) => {
    const subTrees = listOfTrees?.map((tree) => tree?.children?.[key])?.filter(Boolean);

    const allNull = subTrees.every(
      (tree) => tree?.total === 0 || tree?.total === "0" || tree?.total === "None" || tree?.total === "n/a",
    );
    const allSubjectToDeductibleYes = subTrees.every(
      (tree) => tree?.title === "Subject to Deductible" && (tree?.total === "Yes" || tree?.total === 0),
    );
    const shouldPrune = !subTrees.some((tree) => tree?.skipPruning);
    if ((allNull || allSubjectToDeductibleYes) && shouldPrune) {
      listOfTrees.forEach((tree) => {
        if (tree?.children?.[key] == null) return;
        delete tree.children[key];
        tree.childrenKeys = tree.childrenKeys.filter((item) => item !== key);
      });
    }

    pruneAfterFill(subTrees);
  });
};

export const pruneAfterNullPruning = (listOfTrees, hsaContributionOtherValue) => {
  const setOfChildren = [...new Set(listOfTrees?.map((c) => c.childrenKeys).flat(1))];

  if (
    hsaContributionOtherValue !== null &&
    setOfChildren.includes("contributionSelected") &&
    setOfChildren.includes("maximumPersonalContribution")
  ) {
    if (
      listOfTrees.every(
        (tree) => tree.children["contributionSelected"].total <= tree.children["maximumPersonalContribution"].total,
      )
    ) {
      listOfTrees.forEach((tree) => {
        delete tree.children["contributionSelected"];
        delete tree.children["maximumPersonalContribution"];
        tree.childrenKeys = [];
      });
    }
  }
  if (
    setOfChildren.includes("copaysBeforeMaximum") &&
    !(setOfChildren.includes("partialCopayAfterDeductible") || setOfChildren.includes("partialCopayToMeetMaximum"))
  ) {
    listOfTrees.forEach((tree) => {
      if (tree?.children?.["copaysBeforeMaximum"] == null) return;
      delete tree.children["copaysBeforeMaximum"];
      tree.childrenKeys = tree.childrenKeys.filter((item) => item !== "copaysBeforeMaximum");
    });
  }
  if (setOfChildren.includes("partialCopayAfterDeductible") || setOfChildren.includes("partialCopayToMeetMaximum")) {
    listOfTrees.forEach((tree) => {
      if (tree?.children?.["upperLevelCopayPerVisit"] == null) return;
      delete tree.children["upperLevelCopayPerVisit"];
      tree.childrenKeys = tree.childrenKeys.filter((item) => item !== "upperLevelCopayPerVisit");
    });

    listOfTrees.forEach((tree) => {
      if (tree?.children?.["numberOfVisits"] == null) return;
      delete tree.children["numberOfVisits"];
      tree.childrenKeys = tree.childrenKeys.filter((item) => item !== "numberOfVisits");
    });
  }

  setOfChildren.forEach((key) => {
    const subTrees = listOfTrees?.map((tree) => tree?.children?.[key])?.filter(Boolean);

    pruneAfterNullPruning(subTrees, hsaContributionOtherValue);
  });
};

const withoutCompanyLevel = (tree) => {
  const shouldRemove = (keys) => {
    return keys?.length === 1 && ["birthParent", "partner"].includes(keys[0]);
  };

  const processChildren = (children) => {
    const processedChildren = {};
    for (const key in children) {
      processedChildren[key] = withoutCompanyLevel(children[key]);
    }
    return processedChildren;
  };

  const childrenKeys = [...new Set(tree.childrenKeys)];
  const newChildren = shouldRemove(childrenKeys) ? tree.children[tree.childrenKeys[0]].children : tree.children;
  const newChildrenKeys = shouldRemove(childrenKeys) ? tree.children[childrenKeys[0]].childrenKeys : tree.childrenKeys;

  return {
    ...tree,
    children: processChildren(newChildren),
    childrenKeys: newChildrenKeys,
  };
};

const allTreesWithoutCompanyLevel = (listOfTrees) => {
  return listOfTrees.map((tree) => withoutCompanyLevel(tree));
};

const createTrees = (listOfTrees) => {
  const titleOfFirstLevel = listOfTrees?.find((tree) => tree?.title)?.title;

  return listOfTrees?.map((tree) => {
    if (!tree?.title) return createEmptyNode(titleOfFirstLevel);
    return tree;
  });
};

/**
 * Sorts the childrenKeys arrays in the given data structure alphabetically.
 *
 * @param {Object} tree - The tree to sort.
 */
const sortTreeChildren = (tree) => {
  if (tree.children) {
    Object.values(tree.children).forEach(sortTreeChildren);
  }

  if (tree.childrenKeys && tree.childrenKeys.includes("birthParent")) {
    tree.childrenKeys = tree.childrenKeys.filter((item) => item != null);
    tree.childrenKeys.sort((a, b) => {
      if (a === "birthParent") return -1;
      if (a === "partner" && b !== "birthParent") return -1;
      if (b === "partner" && a !== "birthParent") return 1;
      return a.localeCompare(b);
    });
  }
};

const sortAllTrees = (listOfTrees) => {
  listOfTrees.forEach((tree) => sortTreeChildren(tree));
};

const processTrees = (listOfTrees, numberOfTrees, hsaContributionOtherValue = null) => {
  /** START IN PLACE TRANSFORMATIONS */
  pruneBeforeFill(listOfTrees, numberOfTrees);
  recursiveFillTree(listOfTrees);
  sortAllTrees(listOfTrees);
  pruneAfterFill(listOfTrees);
  pruneAfterNullPruning(listOfTrees, hsaContributionOtherValue);
  /** END IN-PLACE TRANSFORMATIONS **/
  listOfTrees = allTreesWithoutCompanyLevel(listOfTrees);

  return listOfTrees;
};

const numberOfTrees = (expected, unexpected, expectedOne, unexpectedOne, expectedTwo, unexpectedTwo, attribute) =>
  [
    expected?.completeCostBreakdown?.[attribute],
    unexpected?.completeCostBreakdown?.[attribute],
    expectedOne?.completeCostBreakdown?.[attribute],
    unexpectedOne?.completeCostBreakdown?.[attribute],
    expectedTwo?.completeCostBreakdown?.[attribute],
    unexpectedTwo?.completeCostBreakdown?.[attribute],
  ].filter((el) => el != null).length;

export const addMissingAttributesToBreakdown = (mainOpt, customOptOne, customOptTwo, preferences) => {
  const { expected, unexpected } = mainOpt || {};
  const { expected: expectedOne, unexpected: unexpectedOne } = customOptOne || {};
  const { expected: expectedTwo, unexpected: unexpectedTwo } = customOptTwo || {};

  const hsaContributionOtherValue =
    preferences["1"].hsaContribution === "other" ? preferences["1"].hsaContributionOtherValue : null;

  let allTrees = {
    insurance: createTrees(
      cloneDeep([
        expected?.completeCostBreakdown?.insurance,
        unexpected?.completeCostBreakdown?.insurance,
        expectedOne?.completeCostBreakdown?.insurance,
        unexpectedOne?.completeCostBreakdown?.insurance,
        expectedTwo?.completeCostBreakdown?.insurance,
        unexpectedTwo?.completeCostBreakdown?.insurance,
      ]),
    ),
    hsaBenefits: createTrees(
      cloneDeep([
        expected?.completeCostBreakdown?.hsaBenefits,
        unexpected?.completeCostBreakdown?.hsaBenefits,
        expectedOne?.completeCostBreakdown?.hsaBenefits,
        unexpectedOne?.completeCostBreakdown?.hsaBenefits,
        expectedTwo?.completeCostBreakdown?.hsaBenefits,
        unexpectedTwo?.completeCostBreakdown?.hsaBenefits,
      ]),
    ),
  };

  allTrees.insurance = processTrees(
    allTrees.insurance,
    numberOfTrees(expected, unexpected, expectedOne, unexpectedOne, expectedTwo, unexpectedTwo, "insurance"),
  );
  allTrees.hsaBenefits = processTrees(
    allTrees.hsaBenefits,
    numberOfTrees(expected, unexpected, expectedOne, unexpectedOne, expectedTwo, unexpectedTwo, "hsaBenefits"),
    hsaContributionOtherValue,
  );

  return {
    main: {
      expected: {
        insurance: allTrees.insurance[0],
        hsaBenefits: allTrees.hsaBenefits[0],
      },
      unexpected: {
        insurance: allTrees.insurance[1],
        hsaBenefits: allTrees.hsaBenefits[1],
      },
    },
    customOne: {
      expected: {
        insurance: allTrees.insurance[2],
        hsaBenefits: allTrees.hsaBenefits[2],
      },
      unexpected: {
        insurance: allTrees.insurance[3],
        hsaBenefits: allTrees.hsaBenefits[3],
      },
    },
    customTwo: {
      expected: {
        insurance: allTrees.insurance[4],
        hsaBenefits: allTrees.hsaBenefits[4],
      },
      unexpected: {
        insurance: allTrees.insurance[5],
        hsaBenefits: allTrees.hsaBenefits[5],
      },
    },

    allTrees: {
      insurance: allTrees.insurance,
      hsaBenefits: allTrees.hsaBenefits,
    },
  };
};

export const calculateFontSizeByLevel = (level) => {
  if (level <= 2) return 16;
  if (level > 4) return 12;
  const toSubtract = (level - 2) * 2;
  return 16 - toSubtract;
};

export const RecursiveRowItem = ({ level, isOpen, onClick, canOpen, children, type = "normal" }) => {
  return (
    <Row justify="start" category={level > 1 && type === "category"} customWidth="100%">
      {canOpen ? (
        <Link
          color={colorFromLevel(isOpen ? level : -1)}
          bold={isOpen}
          fontSize={calculateFontSizeByLevel(level)}
          lHeight={"1rem"}
          justBetween
          onClick={onClick}
        >
          {children}
          <ExpandIcon expanded={isOpen} />
        </Link>
      ) : (
        <Text
          color={colorFromLevel(isOpen ? level : -1)}
          fontSize={calculateFontSizeByLevel(level)}
          lHeight={"1rem"}
          justStart
        >
          {children}
        </Text>
      )}
    </Row>
  );
};
const RecursiveContext = createContext({});
export const RecursiveContextProvider = ({ children }) => {
  const [expand, setExpand] = useState({});

  const toggleExpand = (key) => {
    setExpand({ ...expand, [key]: !expand[key] });
  };

  const resetExpand = () => {
    const keys = Object.keys(expand);
    keys.forEach((key) => {
      if (key.split(".").length > 1) delete expand[key];
    });
  };

  return (
    <RecursiveContext.Provider value={{ expand, toggleExpand, resetExpand }}>{children}</RecursiveContext.Provider>
  );
};

export const useRecursiveWhatIfContext = () => {
  const { expand, toggleExpand, resetExpand } = useContext(RecursiveContext);
  return { expand, toggleExpand, resetExpand };
};

const findItemWithTitle = (itemsArray) => {
  if (!itemsArray) return null;
  for (let i = 0; i < itemsArray.length; i++) {
    if (itemsArray[i]?.title) {
      return itemsArray[i];
    }
  }
  return null;
};

export const RecursiveHeader = ({ items, item, fullPath = "", level }) => {
  const { expand, toggleExpand } = useContext(RecursiveContext);
  const { width } = useWindowDimensions();
  const childrenLevel = level + 1;

  let currentItem = item?.title ? item : findItemWithTitle(items);
  if (!currentItem) return null;

  const hasChildren = Boolean(currentItem?.childrenKeys?.length);
  const childrenKeys = [...new Set(currentItem?.childrenKeys)];

  return (
    <>
      <RecursiveRowItem
        level={level}
        isOpen={expand[fullPath]}
        canOpen={Boolean(childrenKeys.length)}
        onClick={() => toggleExpand(fullPath)}
        type="category"
      >
        {currentItem?.title &&
        currentItem?.title.length > 22 &&
        (level > 3 || fullPath.startsWith("hsaBenefit")) &&
        width < 1650 ? (
          <Tooltip title={currentItem?.title}>{currentItem?.title.slice(0, 22) + "..."}</Tooltip>
        ) : (
          currentItem?.title
        )}
      </RecursiveRowItem>

      <CustomCollapse level={level} isOpened={hasChildren && expand[fullPath]}>
        {childrenKeys.map((key) => (
          <RecursiveHeader
            key={`${key}-total-${currentItem?.total}-level-${level}`}
            items={items.map((item) => item.children[key]).filter(Boolean)}
            item={currentItem.children[key]}
            level={childrenLevel}
            fullPath={`${fullPath}.${key}`}
          />
        ))}
        <RecursiveRowItem level={level} canOpen={false} isOpen={expand[fullPath]}>
          {currentItem?.totalText ? `Total ${currentItem.totalText}` : "Total"}
        </RecursiveRowItem>
      </CustomCollapse>
    </>
  );
};

export const RecursiveCost = ({ item, fullPath = "", level, firstLevel = 1 }) => {
  const { expand } = useContext(RecursiveContext);
  const expanded = expand[fullPath];

  const textProps = { center: true, fontSize: calculateFontSizeByLevel(level) };
  if (!item) return null;

  if (!expanded) {
    return (
      <Row key={`${fullPath}.total`} color="tertiary" justify="center" category={level > firstLevel}>
        <TableCell tall middle>
          <Text {...textProps}> {formatCurrency(item.total)}</Text>
        </TableCell>
        <TableCell tall width="20px" />
        <TableCell tall middle>
          <Text {...textProps}> {formatCurrency(item.total)}</Text>
        </TableCell>
      </Row>
    );
  }

  return (
    <>
      <Row justify="start" category={level > firstLevel} />
      {Boolean(item?.childrenKeys?.length) &&
        item.childrenKeys.map((key) => (
          <RecursiveCost
            key={item.title}
            item={item.children[key]}
            level={level + 1}
            fullPath={`${fullPath}.${key}`}
            firstLevel={firstLevel}
          />
        ))}

      <Row color="tertiary" justify="center">
        <TableCell tall middle>
          <Text {...textProps}> {formatCurrency(item.total)}</Text>
        </TableCell>
        <TableCell tall width="20px" />
        <TableCell tall middle>
          <Text {...textProps}> {formatCurrency(item.total)}</Text>
        </TableCell>
      </Row>
    </>
  );
};

export const RecursiveCostTwoColumn = ({ items, fullPath = "", level, firstLevel = 1 }) => {
  const { expand } = useContext(RecursiveContext);
  const expanded = expand[fullPath];

  const textProps = { center: true, fontSize: calculateFontSizeByLevel(level) };
  if (!items.filter(Boolean)?.length) return null;

  if (!expanded) {
    return (
      <Row key={`${fullPath}.total`} color="tertiary" justify="center" category={level > firstLevel}>
        <TableCell tall middle>
          <Text {...textProps}> {formatCurrency(items?.[0]?.total)}</Text>
        </TableCell>
        <TableCell tall width="20px" />
        <TableCell tall middle>
          <Text {...textProps}> {formatCurrency(items?.[1]?.total)}</Text>
        </TableCell>
      </Row>
    );
  }

  const allChildrenKeys = [...new Set(items?.map((c) => c?.childrenKeys || []).flat(1))];

  return (
    <>
      <Row justify="start" category={level > firstLevel} />
      {Boolean(allChildrenKeys?.length) &&
        allChildrenKeys?.map((key) => (
          <RecursiveCostTwoColumn
            key={`${fullPath}.${key}`}
            items={items?.map((item) => item?.children?.[key])}
            level={level + 1}
            fullPath={`${fullPath}.${key}`}
            firstLevel={firstLevel}
          />
        ))}

      <Row color="tertiary" justify="center">
        <TableCell tall middle>
          <Text {...textProps}> {formatCurrency(items?.[0]?.total)}</Text>
        </TableCell>
        <TableCell tall width="20px" />
        <TableCell tall middle>
          <Text {...textProps}> {formatCurrency(items?.[1]?.total)}</Text>
        </TableCell>
      </Row>
    </>
  );
};
