import moment from "moment";

export const getDateFromMonth = (date, year = null) => {
  const currentDate = moment().set("date", 1);
  const selectedMonth = moment(date).month();

  if (year) {
    return currentDate.clone().set("month", selectedMonth).set("year", year);
  }

  const realDate = currentDate.clone().set("month", selectedMonth).add(1, "years");
  const timeTo = realDate.diff(currentDate, "months");
  timeTo > 4 && realDate.subtract(1, "years");
  return realDate;
};
