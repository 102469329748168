import React from "react";
import styled from "styled-components";
import { Modal, Form, message, Upload, Button, Select, Checkbox, DatePicker } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import {
  ListGroupEmployeesJobOffers,
  DynamicFieldAddEmployee,
  DynamicFieldRemoveEmployee,
  DynamicFieldJobOffer,
} from "components";
import { updateGroup } from "services/api/groups.api";
import { mergeUsers } from "services/api/account.api";
import moment from "moment";

const CustomModal = styled(Modal)`
  min-width: ${(props) => (props.minWidth ? props.minWidth : "40vw")};
  .ant-modal-body {
    overflow: auto;
    padding-bottom: 24px;
  }

  @media (max-width: 1280px) {
    min-width: 60vw;
  }

  @media (max-width: 1100px) {
    min-width: 90vw;
  }

  label {
    width: 100% !important;
  }
`;

export const ViewModal = ({ modal, closeModalCleanStates, group, year, handleExport }) => (
  <CustomModal
    title="Current and Former Employees"
    open={modal}
    onOk={() => {
      closeModalCleanStates();
    }}
    onCancel={() => {
      closeModalCleanStates();
    }}
    cancelButtonProps={{ style: { display: "none" } }}
  >
    <div>
      <div
        style={{
          position: "absolute",
          left: "calc(100% - 89.61px)",
        }}
      >
        <Button onClick={handleExport}>Export</Button>
      </div>
      <ListGroupEmployeesJobOffers group={group} year={year} />
      <div
        style={{
          position: "absolute",
          left: "calc(100% - 89.61px)",
          top: "calc(100% - 109px)",
          display:
            (group?.currentEmployeeMap ? Object.keys(group.currentEmployeeMap).length : 0) +
              (group?.formerEmployeeMap ? Object.keys(group.formerEmployeeMap).length : 0) >
            30
              ? "initial"
              : "none",
        }}
      >
        <Button onClick={handleExport}>Export</Button>
      </div>
    </div>
  </CustomModal>
);

export const SelectAddTypeModal = ({ modal, closeModalCleanStates, setModalOption }) => (
  <CustomModal
    title="Add Manually"
    open={modal}
    onCancel={() => {
      closeModalCleanStates();
    }}
    footer={null}
  >
    <div style={{ display: "flex", justifyContent: "space-evenly", marginBottom: "10px" }}>
      <Button onClick={() => setModalOption("add-manually")}>New Hires</Button>
      <Button onClick={() => setModalOption("terminate-manually")}>Terminations</Button>
      <Button onClick={() => setModalOption("job-offers")}>Job Offers</Button>
    </div>
  </CustomModal>
);

export const AddManuallyModal = ({ modal, closeModalCleanStates, form, group, year, premiumTiers, setGroup }) => (
  <CustomModal
    title="Add Employee Access Manually"
    open={modal}
    destroyOnClose={true}
    onOk={async () => {
      form.submit();
    }}
    okText={"Submit"}
    onCancel={() => {
      closeModalCleanStates();
    }}
    cancelButtonProps={{ style: { display: "none" } }}
  >
    <Form
      form={form}
      onFinish={async (values) => {
        if (!values.employeesAdded || values.employeesAdded.length === 0) {
          message.error("Please add at least one employee");
          return;
        }

        if (premiumTiers.length === 1) {
          values.employeesAdded.forEach((item) => {
            item.role = premiumTiers[0];
          });
        }
        if (values.employeesAdded && !values.employeesAdded.every((item) => item.email && item.role && item.date)) {
          message.error("Please make sure all employees are filled out correctly");
          return;
        }
        if (
          values.employeesAdded &&
          values.employeesAdded.map((item) => item.email).length !==
            [...new Set(values.employeesAdded.map((item) => item.email))].length
        ) {
          message.error("The same email cannot be added multiple times");
          return;
        }
        values.employeesAdded.forEach((item) => {
          if (group.currentEmployeeMap?.[item.email]) {
            item.roles = { ...group.currentEmployeeMap[item.email].roles, [year]: item.role };
          } else {
            item.roles = { [year]: item.role };
          }
          delete item.role;
          const formatDate = (date) =>
            `${("0" + (date.getMonth() + 1)).slice(-2)}/${("0" + date.getDate()).slice(-2)}/${date.getFullYear()}`;
          item.date = formatDate(new Date(item.date));
        });
        const { status, data } = await updateGroup({
          originalName: group.name,
          form: { employeesAdded: values.employeesAdded },
        });
        status === 200 && setGroup(data.group);
        status === 200 && form.resetFields();
        status === 200 && closeModalCleanStates();
      }}
    >
      <DynamicFieldAddEmployee
        name="employeesAdded"
        label="New Hires"
        placeholder="mark@company.com"
        premiumTiers={premiumTiers}
      />
    </Form>
  </CustomModal>
);

export const TerminateManuallyModal = ({ modal, closeModalCleanStates, form, group, setGroup }) => (
  <CustomModal
    title="Remove Employee Access Manually"
    open={modal}
    destroyOnClose={true}
    onOk={async () => {
      form.submit();
    }}
    okText={"Submit"}
    onCancel={() => {
      closeModalCleanStates();
    }}
    cancelButtonProps={{ style: { display: "none" } }}
  >
    <Form
      form={form}
      onFinish={async (values) => {
        if (!values.employeesRemoved || values.employeesRemoved.length === 0) {
          message.error("Please add at least one employee");
          return;
        }
        if (
          values.employeesRemoved &&
          !values.employeesRemoved.every((item) => item.email && item.option && item.date)
        ) {
          message.error("Please make sure all fields are filled out correctly");
          return;
        }
        if (
          values.employeesRemoved &&
          values.employeesRemoved.map((item) => item.email).length !==
            [...new Set(values.employeesRemoved.map((item) => item.email))].length
        ) {
          message.error("The same email cannot be included multiple times");
          return;
        }
        values.employeesRemoved.forEach((item) => {
          if (group.currentEmployeeMap[item.email]) {
            item.roles = { ...group.currentEmployeeMap[item.email].roles };
          }
          const formatDate = (date) =>
            `${("0" + (date.getMonth() + 1)).slice(-2)}/${("0" + date.getDate()).slice(-2)}/${date.getFullYear()}`;
          item.date = formatDate(new Date(item.date));
        });
        const { status, data } = await updateGroup({
          originalName: group.name,
          form: { employeesRemoved: values.employeesRemoved },
        });
        status === 200 && setGroup(data.group);
        status === 200 && form.resetFields();
        status === 200 && closeModalCleanStates();
      }}
    >
      <DynamicFieldRemoveEmployee
        name="employeesRemoved"
        label="Terminations"
        emailList={group.currentEmployeeMap && Object.keys(group.currentEmployeeMap)}
      />
    </Form>
  </CustomModal>
);

export const JobOffersModal = ({ modal, closeModalCleanStates, form, group, year, premiumTiers, setGroup }) => (
  <CustomModal
    title="Edit Job Offers"
    open={modal}
    destroyOnClose={true}
    onOk={async () => {
      form.submit();
    }}
    okText={"Submit"}
    onCancel={() => {
      closeModalCleanStates();
    }}
    cancelButtonProps={{ style: { display: "none" } }}
  >
    <Form
      form={form}
      onFinish={async (values) => {
        if (!group.jobOfferMap) {
          group.jobOfferMap = {};
        }
        if (premiumTiers.length === 1) {
          (() =>
            values.jobOfferMap?.forEach((item) => {
              item.role = premiumTiers[0];
            }))();
        }
        if (values.jobOfferMap && !values.jobOfferMap.every((item) => item.email && item.role)) {
          message.error("Please make sure all job offers are filled out correctly");
          return;
        }

        if (
          values.jobOfferMap &&
          values.jobOfferMap.map((item) => item.email).length !==
            [...new Set(values.jobOfferMap.map((item) => item.email))].length
        ) {
          message.error("The same email cannot appear in job offer list multiple times");
          return;
        }

        values.jobOfferMap.forEach((item) => {
          if (group.jobOfferMap[item.email]) {
            item.roles = { ...group.jobOfferMap[item.email].roles, [year]: item.role };
          } else {
            item.roles = { [year]: item.role };
          }
          delete item.role;
        });
        const { status, data } = await updateGroup({
          originalName: group.name,
          form: { jobOfferMap: values.jobOfferMap },
        });
        status === 200 && setGroup(data.group);
        status === 200 && form.resetFields();
        status === 200 && closeModalCleanStates();
      }}
      initialValues={{
        jobOfferMap:
          group.jobOfferMap &&
          Object.keys(group.jobOfferMap).map((key) => ({
            email: key,
            role: group.jobOfferMap[key]?.roles?.[year],
          })),
      }}
    >
      <DynamicFieldJobOffer
        name="jobOfferMap"
        label="Job Offer Emails"
        placeholder="jim@gmail.com"
        premiumTiers={premiumTiers}
      />
    </Form>
  </CustomModal>
);

export const UploadModal = ({
  modal,
  closeModalCleanStates,
  onInsertFile,
  employeesFileList,
  employeesAdded,
  employeesRemoved,
  employeesWithWrongRole,
  setUploadType,
}) => (
  <CustomModal
    title="Employee Updates"
    open={modal}
    onOk={() => {
      closeModalCleanStates();
    }}
    onCancel={() => {
      closeModalCleanStates();
    }}
    footer={null}
  >
    <div style={{ display: "flex", justifyContent: "space-evenly", marginBottom: "10px" }}>
      <div>
        <Upload
          accept={".csv, .xlsx"}
          maxCount={1}
          beforeUpload={() => false}
          fileList={employeesFileList}
          onChange={(event) => {
            onInsertFile(event);
          }}
          onClick={() => setUploadType("update")}
        >
          <Button icon={<UploadOutlined />}>Add New Hires and Terminations</Button>
        </Upload>
        {employeesFileList.length > 0 &&
          employeesAdded.length + employeesRemoved.length + employeesWithWrongRole.length === 0 && (
            <div style={{ fontSize: "12px" }}>No changes detected in uploaded file.</div>
          )}
      </div>
      <Upload
        accept={".csv, .xlsx"}
        maxCount={1}
        beforeUpload={() => false}
        fileList={employeesFileList}
        onChange={(event) => {
          onInsertFile(event);
        }}
        onClick={() => setUploadType("census")}
      >
        <Button icon={<UploadOutlined />}>Upload Census</Button>
      </Upload>
    </div>
  </CustomModal>
);

export const ResultsModal = ({
  modal,
  closeModalCleanStates,
  group,
  setGroup,
  year,
  employeesAdded,
  setEmployeesAdded,
  employeesRemoved,
  setEmployeesRemoved,
  employeesWithWrongRole,
  setEmployeesWithWrongRole,
  wrongRolesAdjustments,
  setWrongRolesAdjustments,
  newHires,
  currentEmployees,
  premiumTiers,
}) => {
  const onOk = async () => {
    const { status, data } = await updateGroup({
      originalName: group.name,
      form: { employeesAdded, employeesRemoved },
    });
    status === 200 && setGroup(data.group);
    status === 200 && closeModalCleanStates();
  };

  return (
    <CustomModal
      title="Changes to existing employees"
      open={modal}
      onOk={onOk}
      okText={"Submit"}
      onCancel={() => {
        closeModalCleanStates();
      }}
      okButtonProps={{ disabled: employeesWithWrongRole.length > 0 }}
      cancelButtonProps={{ style: { display: "none" } }}
    >
      <div>
        {employeesWithWrongRole.length > 0 && (
          <div style={{ marginBottom: "20px" }}>
            <h3>Please confirm which premium tiers the following text from your file refers to</h3>
            {[...new Set(employeesWithWrongRole.map((employee) => employee.role))].map((role) => (
              <div key={role}>
                <div style={{ display: "inline-flex", alignItems: "center", marginBottom: "5px", width: "100%" }}>
                  <div style={{ width: "300px" }}>{role}</div>
                  <Select
                    style={{ width: "100%", maxWidth: "350px", marginLeft: "5px" }}
                    dropdownMatchSelectWidth={500}
                    onChange={(value) => {
                      setWrongRolesAdjustments({ ...wrongRolesAdjustments, [role]: value });
                    }}
                    options={premiumTiers.map((tier) => ({ value: tier, label: tier }))}
                  />
                </div>
              </div>
            ))}
            <Button
              type="primary"
              disabled={Object.keys(wrongRolesAdjustments).length === 0}
              style={{ marginBottom: "20px" }}
              onClick={() => {
                const newEmployeesAdded = employeesAdded.map((employee) => {
                  if (Object.keys(wrongRolesAdjustments).includes(employee.role)) {
                    return {
                      ...employee,
                      role: wrongRolesAdjustments[employee.role],
                      roles: { ...employee.roles, [year]: wrongRolesAdjustments[employee.role] },
                    };
                  }
                  return employee;
                });
                const newEmployeesRemoved = employeesRemoved.map((employee) => {
                  if (Object.keys(wrongRolesAdjustments).includes(employee.role)) {
                    return {
                      ...employee,
                      role: wrongRolesAdjustments[employee.role],
                      roles: { ...employee.roles, [year]: wrongRolesAdjustments[employee.role] },
                    };
                  }
                  return employee;
                });
                setEmployeesAdded(newEmployeesAdded);
                setEmployeesRemoved(newEmployeesRemoved);
                setEmployeesWithWrongRole(
                  employeesWithWrongRole.filter(
                    (employee) => !Object.keys(wrongRolesAdjustments).includes(employee.role),
                  ),
                );
              }}
            >
              Apply
            </Button>
            <h2 style={{ marginBottom: "5px" }}>Errors</h2>
            {employeesWithWrongRole.map((employee, idx) => (
              <div key={idx}>
                {employee.email} - {employee.role} - {employee.date}
              </div>
            ))}
          </div>
        )}
        {/* calc(100% - (76.7 from button size + 16px from footer padding) = 92.7px) */}
        <div
          style={{
            position: "absolute",
            left: "calc(100% - 92.7px)",
            display: employeesAdded.length + employeesRemoved.length > 30 ? "initial" : "none",
          }}
        >
          <Button type="primary" disabled={employeesWithWrongRole.length > 0} onClick={onOk}>
            Submit
          </Button>
        </div>
        {newHires.length > 0 && (
          <div style={{ marginBottom: "20px" }}>
            <h2 style={{ marginBottom: "5px" }}>New Hires</h2>
            {newHires.map((employee, idx) => (
              <div key={idx}>
                {employee.email} - {employee.role} - {employee.date}
              </div>
            ))}
          </div>
        )}
        {currentEmployees.length > 0 && (
          <div style={{ marginBottom: "20px" }}>
            <h2 style={{ marginBottom: "5px" }}>Current Employees</h2>
            {currentEmployees.map((employee, idx) => (
              <div key={idx}>
                {employee.email} - {employee.role} - {employee.date}
              </div>
            ))}
          </div>
        )}
        {employeesRemoved.length > 0 && (
          <div style={{ marginBottom: "20px" }}>
            <h2 style={{ marginBottom: "5px" }}>Terminations</h2>
            {employeesRemoved.map((employee, idx) => (
              <div key={idx}>
                {employee.email} - {employee.role} - {employee.date}
              </div>
            ))}
          </div>
        )}
      </div>
    </CustomModal>
  );
};

export const FixEmployeesModal = ({
  modal,
  closeModalCleanStates,
  group,
  setGroup,
  year,
  employeesWithMissingPremiumTier,
  setEmployeesWithMissingPremiumTier,
  wrongRolesAdjustments,
  setWrongRolesAdjustments,
  premiumTiers,
}) => (
  <CustomModal
    title="Update employee premium tiers"
    open={modal}
    onOk={async () => {
      const { status, data } = await updateGroup({
        originalName: group.name,
        form: {
          employeesAdded: employeesWithMissingPremiumTier.currentEmployeeMap,
          employeesRemoved: employeesWithMissingPremiumTier.formerEmployeeMap,
          jobOfferMap: group.jobOfferMap
            ? [
                ...Object.keys(group.jobOfferMap).map((key) => group.jobOfferMap[key]),
                ...employeesWithMissingPremiumTier.jobOfferMap,
              ]
            : [],
        },
      });
      status === 200 && setGroup(data.group);
      closeModalCleanStates();
    }}
    okText={"Submit"}
    onCancel={() => {
      closeModalCleanStates();
    }}
    okButtonProps={{
      style: { display: employeesWithMissingPremiumTier.wrongRoles.length === 0 ? "initial" : "none" },
    }}
    cancelButtonProps={{ style: { display: "none" } }}
  >
    <div>
      {employeesWithMissingPremiumTier.wrongRoles.length > 0 && (
        <div style={{ marginBottom: "20px" }}>
          <h3>Please confirm which premium tiers the following text refers to</h3>
          {[...new Set(employeesWithMissingPremiumTier["wrongRoles"].map((employee) => employee.roles?.[year]))].map(
            (role) => (
              <div key={role}>
                <div style={{ display: "inline-flex", alignItems: "center", marginBottom: "5px", width: "100%" }}>
                  <div style={{ width: "300px" }}>{role}</div>
                  <Select
                    style={{ minWidth: "350px" }}
                    dropdownMatchSelectWidth={500}
                    onChange={(value) => {
                      setWrongRolesAdjustments({ ...wrongRolesAdjustments, [role]: value });
                    }}
                    options={premiumTiers.map((tier) => ({ value: tier, label: tier }))}
                  />
                </div>
              </div>
            ),
          )}
          <Button
            type="primary"
            disabled={Object.keys(wrongRolesAdjustments).length === 0}
            onClick={() => {
              const newEmployeesAdded = employeesWithMissingPremiumTier["currentEmployeeMap"].map((employee) => {
                if (Object.keys(wrongRolesAdjustments).includes(employee.roles?.[year])) {
                  return {
                    ...employee,
                    roles: { ...employee.roles, [year]: wrongRolesAdjustments[employee.roles?.[year]] },
                  };
                }
                return employee;
              });
              const newEmployeesRemoved = employeesWithMissingPremiumTier["formerEmployeeMap"].map((employee) => {
                if (Object.keys(wrongRolesAdjustments).includes(employee.roles?.[year])) {
                  return {
                    ...employee,
                    roles: { ...employee.roles, [year]: wrongRolesAdjustments[employee.roles?.[year]] },
                  };
                }
                return employee;
              });
              const newJobOffers = employeesWithMissingPremiumTier["jobOfferMap"].map((employee) => {
                if (Object.keys(wrongRolesAdjustments).includes(employee.roles?.[year])) {
                  return {
                    ...employee,
                    roles: { ...employee.roles, [year]: wrongRolesAdjustments[employee.roles?.[year]] },
                  };
                }
                return employee;
              });
              setEmployeesWithMissingPremiumTier({
                currentEmployeeMap: newEmployeesAdded,
                formerEmployeeMap: newEmployeesRemoved,
                jobOfferMap: newJobOffers,
                wrongRoles: employeesWithMissingPremiumTier["wrongRoles"].filter(
                  (item) => !Object.keys(wrongRolesAdjustments).includes(item.roles?.[year]),
                ),
              });
            }}
          >
            Apply
          </Button>
        </div>
      )}

      <h3>List of employees, former employees and job offers affected:</h3>
      {employeesWithMissingPremiumTier["currentEmployeeMap"].map((employee, idx) => (
        <div key={idx}>
          {employee.email} - {employee.roles?.[year] ? employee.roles?.[year] : "No premium tier assigned"}
        </div>
      ))}
      {employeesWithMissingPremiumTier["formerEmployeeMap"].map((employee, idx) => (
        <div key={idx}>
          {employee.email} - {employee.roles?.[year] ? employee.roles?.[year] : "No premium tier assigned"}
        </div>
      ))}
      {employeesWithMissingPremiumTier["jobOfferMap"].map((employee, idx) => (
        <div key={idx}>
          {employee.email} - {employee.roles?.[year] ? employee.roles?.[year] : "No premium tier assigned"}
        </div>
      ))}
    </div>
  </CustomModal>
);

export const NewFileFormatModal = ({
  modal,
  closeModalCleanStates,
  jsonToSubmit,
  dataContainsHeaders,
  setDataContainsHeaders,
  headerList,
  newFileFormatHeaders,
  newFileFormatHeadersAdjustments,
  setNewFileFormatHeadersAdjustments,
  submitFormJSON,
  premiumTiers,
}) => (
  <CustomModal
    title="New file format detected"
    open={modal}
    onOk={() => {
      const newJson = jsonToSubmit.map((tab, tabIdx) => {
        const result = [];
        let transformedRow = {};
        if (tabIdx === 0 && !dataContainsHeaders) {
          for (const [column, index] of Object.entries(newFileFormatHeadersAdjustments)) {
            transformedRow[column] = Object.keys(tab[0])[index];
          }
          result.push(transformedRow);
        }
        for (const row of tab) {
          transformedRow = {};
          for (const [column, index] of Object.entries(newFileFormatHeadersAdjustments)) {
            const key = Object.keys(row)[index];
            transformedRow[column] = row[key];
          }
          result.push(transformedRow);
        }
        return result;
      });
      submitFormJSON(newJson);
    }}
    onCancel={() => {
      closeModalCleanStates();
    }}
    okButtonProps={{
      disabled:
        !Object.keys(newFileFormatHeadersAdjustments).includes("Email") ||
        (premiumTiers.length > 1 && !Object.keys(newFileFormatHeadersAdjustments).includes("Premium Tier")),
    }}
  >
    <div>
      <h3>Please let us know which columns match the required fields</h3>
      {headerList.map((header, idx) => (
        <div key={header}>
          <div style={{ display: "inline-flex", alignItems: "center", marginBottom: "5px", width: "100%" }}>
            <div style={{ width: "300px" }}>{`${header}${
              idx >= (premiumTiers.length > 1 ? 2 : 1) ? " (Optional)" : ""
            }`}</div>
            <Select
              key={header}
              style={{ width: "100%", maxWidth: "350px", marginLeft: "5px" }}
              dropdownMatchSelectWidth={500}
              onChange={(value) => {
                setNewFileFormatHeadersAdjustments({ ...newFileFormatHeadersAdjustments, [header]: value });
              }}
              options={newFileFormatHeaders
                .filter((item) => !Object.values(newFileFormatHeadersAdjustments).includes(item.index))
                .map((item) => ({ value: item.index, label: item.header }))}
            />
          </div>
        </div>
      ))}
      <Checkbox
        checked={dataContainsHeaders}
        onChange={(event) => setDataContainsHeaders(event.target.checked)}
        style={{ marginTop: "40px" }}
      >
        My data has headers
      </Checkbox>
    </div>
  </CustomModal>
);

export const FixCensusModal = ({
  modal,
  closeModalCleanStates,
  group,
  setGroup,
  employeesNotInCensus,
  employeesNotInCensusAdjustments,
  setEmployeesNotInCensusAdjustments,
  employeesAdded,
  employeesRemoved,
  employeesWithWrongRole,
  setModalOption,
}) => {
  const employeesNotInCensusEmails = employeesNotInCensus.map((employee) => employee.email);
  const formatDate = (date) =>
    `${("0" + (date.getMonth() + 1)).slice(-2)}/${("0" + date.getDate()).slice(-2)}/${date.getFullYear()}`;
  const currentEmployeesEmails = Object.keys(group.currentEmployeeMap);
  return (
    <CustomModal
      title="Employees to Remove"
      open={modal}
      minWidth={"50vw"}
      onOk={async () => {
        const newEmailEmployees = employeesNotInCensus
          .filter((employee) => employeesNotInCensusAdjustments[employee.email]?.option === "new-email")
          .map((employee) => employee.email);

        if (newEmailEmployees.length) {
          await mergeUsers({
            userList: newEmailEmployees.map((email) => ({
              primaryEmail: employeesNotInCensusAdjustments[email].newEmail,
              secondaryEmail: email,
            })),
            groupDomainList: group.otherDomains,
          });
        }

        const newFormerEmployees = employeesNotInCensus
          .filter(
            (employee) =>
              employeesNotInCensusAdjustments[employee.email]?.option !== "current-employee" &&
              employeesNotInCensusAdjustments[employee.email]?.option !== "new-email" &&
              employeesNotInCensusAdjustments[employee.email]?.option !== "ineligible",
          )
          .map((employee) => ({ ...employee, ...employeesNotInCensusAdjustments[employee.email] }));

        const newIneligibleEmployees = employeesNotInCensus
          .filter((employee) => employeesNotInCensusAdjustments[employee.email]?.option === "ineligible")
          .map((employee) => ({ ...employee, date: employeesNotInCensusAdjustments[employee.email].date }));

        const { status, data } = await updateGroup({
          originalName: group.name,
          form: {
            employeesRemoved: newFormerEmployees,
            employeesToDelete: newEmailEmployees,
            employeesIneligible: newIneligibleEmployees,
          },
        });
        status === 200 && setGroup(data.group);
        if (employeesAdded.length + employeesRemoved.length + employeesWithWrongRole.length > 0) {
          setModalOption("results");
        } else {
          closeModalCleanStates();
        }
      }}
      onCancel={() => {
        closeModalCleanStates();
      }}
      okButtonProps={{
        disabled:
          Object.keys(employeesNotInCensusAdjustments).length < employeesNotInCensus.length ||
          !Object.keys(employeesNotInCensusAdjustments).every(
            (key) =>
              (employeesNotInCensusAdjustments[key].option === "new-email" &&
                employeesNotInCensusAdjustments[key].newEmail) ||
              employeesNotInCensusAdjustments[key].option === "current-employee" ||
              (employeesNotInCensusAdjustments[key].option && employeesNotInCensusAdjustments[key].date),
          ),
      }}
    >
      <div>
        <p>
          These email addresses are for employees who were previously added to the database but who are not included in
          the census file just uploaded. Please indicate how they should be classified.
        </p>
        <table
          style={{ borderCollapse: "inherit", borderSpacing: "5px 0", width: "calc(100% + 10px)", marginLeft: "-5px" }}
        >
          <colgroup>
            <col style={{ width: "40%" }} />
            <col style={{ width: "30%" }} />
            <col style={{ width: "10%" }} />
            <col style={{ width: "20%" }} />
          </colgroup>

          <thead style={{ textAlign: "left" }}>
            <tr>
              <th>Email</th>
              <th>Reason for Removal</th>
            </tr>
          </thead>
          <tbody>
            {employeesNotInCensusEmails.map((employeeEmail, idx) => {
              const option = employeesNotInCensusAdjustments[employeeEmail]?.option;
              return (
                <tr key={idx}>
                  <td>{employeeEmail}</td>
                  <td style={{ maxWidth: "270px" }}>
                    <Select
                      key={employeeEmail}
                      style={{ width: "100%" }}
                      dropdownMatchSelectWidth={400}
                      onChange={(value) => {
                        setEmployeesNotInCensusAdjustments({
                          ...employeesNotInCensusAdjustments,
                          [employeeEmail]: {
                            option: value,
                          },
                        });
                      }}
                      options={[
                        { value: "new-email", label: "Under different email address / name change" },
                        { value: "ineligible", label: "Not currently benefit eligible" },
                        { value: "terminated", label: "Terminated" },
                        { value: "retired", label: "Retired" },
                        { value: "deceased", label: "Deceased" },
                        { value: "current-employee", label: "Keep employee — this is a new hire or current employee" },
                      ]}
                    />
                  </td>
                  {option && (
                    <td style={{ textAlign: "center" }}>
                      {option === "new-email" && "  "}
                      {(option === "ineligible" || option === "deceased") && "as of"}
                      {(option === "retired" || option === "terminated") && "on"}
                    </td>
                  )}
                  {option === "new-email" && (
                    <td style={{ maxWidth: "220px" }}>
                      <Select
                        showSearch
                        style={{ width: "100%" }}
                        placeholder="Type to search an existing email"
                        options={currentEmployeesEmails
                          .filter((currEmployeeEmail) => !employeesNotInCensusEmails.includes(currEmployeeEmail))
                          .map((email) => ({ value: email }))}
                        onSelect={(value) => {
                          setEmployeesNotInCensusAdjustments({
                            ...employeesNotInCensusAdjustments,
                            [employeeEmail]: {
                              option: "new-email",
                              newEmail: value,
                            },
                          });
                        }}
                        filterOption={(input, option) =>
                          (option?.value ?? "").toLowerCase().includes(input.toLowerCase())
                        }
                      />
                    </td>
                  )}
                  {option && option !== "new-email" && option !== "current-employee" && (
                    <td>
                      {
                        <DatePicker
                          format={["MM/DD/YYYY", "MM/DD/YY", "M/D/YYYY", "M/D/YY"]}
                          style={{ width: "100%" }}
                          onChange={(value) => {
                            setEmployeesNotInCensusAdjustments({
                              ...employeesNotInCensusAdjustments,
                              [employeeEmail]: {
                                option: option,
                                date: value ? formatDate(new Date(value)) : null,
                              },
                            });
                          }}
                        />
                      }
                    </td>
                  )}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </CustomModal>
  );
};

export const DatePickerModal = ({ modal, closeModalCleanStates, modalDate, setModalDate, title, paragraph, onOk }) => (
  <CustomModal
    title={title}
    open={modal}
    onOk={onOk}
    onCancel={() => {
      closeModalCleanStates();
    }}
  >
    <div style={{ display: "inline-flex", alignItems: "center" }}>
      <p style={{ margin: "0 8px 0 0" }}>{paragraph}</p>
      <DatePicker
        format={["MM/DD/YYYY", "MM/DD/YY", "M/D/YYYY", "M/D/YY"]}
        defaultValue={modalDate && moment(modalDate, "MM/DD/YYYY")}
        onChange={setModalDate}
      />
    </div>
  </CustomModal>
);

export const ExportEmployeesModal = ({
  modal,
  closeModalCleanStates,
  onOk,
  modalDate,
  setModalDate,
  exportColumns,
  setExportColumns,
  exportColumnsOption,
  setExportColumnsOption,
}) => {
  const options = [
    { label: "Confirmation Email Address", value: "email" },
    { label: "Premium Tier", value: "premium tier" },
    { label: "Hire Date (if available)", value: "hire date" },
    { label: "Termination Date (if available)", value: "termination date" },
  ];
  return (
    <CustomModal
      title="Export Employee Data"
      open={modal}
      onOk={onOk}
      onCancel={() => {
        closeModalCleanStates();
      }}
      okButtonProps={{ disabled: exportColumnsOption === "custom" && exportColumns.length === 0 }}
    >
      <div>
        <p style={{ margin: "0", fontWeight: "700" }}>Date</p>
        <div style={{ display: "inline-flex", alignItems: "center" }}>
          <p style={{ margin: "0 8px 0 0" }}>Employees as of</p>
          <DatePicker
            format={["MM/DD/YYYY", "MM/DD/YY", "M/D/YYYY", "M/D/YY"]}
            defaultValue={modalDate && moment(modalDate, "MM/DD/YYYY")}
            onChange={setModalDate}
            allowClear={false}
          />
        </div>
        <div style={{ marginTop: "20px" }}>
          <p style={{ margin: "0", fontWeight: "700" }}>Columns</p>
          <Select
            style={{ width: "100%" }}
            options={[
              {
                value: "all",
                label: "All columns (4)",
              },
              {
                value: "custom",
                label: "Custom",
              },
            ]}
            value={exportColumnsOption}
            onChange={(value) => setExportColumnsOption(value)}
          />
          <div style={{ marginTop: "10px" }}>
            {exportColumnsOption === "all" ? (
              <p style={{ margin: "0" }}>{options.map((option) => option.label).join(", ")}</p>
            ) : (
              <Checkbox.Group value={exportColumns} onChange={(values) => setExportColumns(values)} options={options} />
            )}
          </div>
        </div>
      </div>
    </CustomModal>
  );
};
