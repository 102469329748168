import { getNetworksGrid } from "services/api";

export const loadInsuranceNetworks = async (id) => {
  if (!id) return {};
  const { status, data } = await getNetworksGrid({ id });
  if (status !== 200) return {};
  const { networks } = data?.grid;

  const allowedInsuranceNetworks = networks
    ? networks.map((network) => {
        return {
          name: `${network.name} ${network.row2.replaceAll(network.name, "").trim()}`.trim(),
          value: network.row2 && network.row2 !== "" ? `${network.name};${network.row2.trim()}` : network.name,
        };
      })
    : [];

  return {
    allowedInsuranceNetworks,
  };
};
