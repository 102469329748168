import React, { useState, useEffect } from "react";
import styled, { css } from "styled-components";
import { connect } from "react-redux";
import Actions from "store/auth/actions";
import { Link, ButtonLink } from "components";
import { Drawer } from "antd";
import { Icon } from "@ant-design/compatible";

import { getToken, getUserId } from "services/utils";
import { getRole, isProfessional } from "services/api/admin.api";
import { getProfile } from "services/api/account.api";

const Nav = styled.nav`
  display: flex;
  list-style: none;
  align-items: center;
  justify-content: flex-end;
  flex-grow: 1;
  z-index: 2px;
  ${({ isTypeform }) =>
    isTypeform &&
    css`
      position: absolute;
      top: 20px;
      right: 20px;
      z-index: 999;
    `}
`;

const FlexWrapper = styled.div`
  font-family: circular;
  font-weight: 400;
  font-style: normal;
  font-size: 12px;
  text-transform: uppercase;

  ${({ invert }) =>
    invert &&
    css`
      > * {
        color: #1c355e;
        :hover {
          text-decoration: underline;
        }
      }
    `}
  display: flex;
  align-items: center;

  ${({ drawer }) =>
    drawer &&
    css`
      flex-direction: column;
      align-items: start;
      font-size: 14px;
      > * {
        color: #fff;
        padding: 15px 0;
      }
    `}
`;

const PrimaryNavigation = ({
  logout,
  tryIt,
  currentReport,
  isAuth,
  destinationRoute,
  isTypeform,
  userId,
  ...props
}) => {
  const [toggle, setToggle] = useState(false);
  const [finishedGetRoleCall, setFinishedGetRoleCall] = useState(false);
  const [isAllowed, setIsAllowed] = useState(false);
  const [user, setUser] = useState(null);

  const showDrawer = () => {
    setToggle(true);
  };
  const onClose = () => {
    setToggle(false);
  };

  const roleCheck = async () => {
    const id = await getUserId();
    if (!id) {
      setIsAllowed(false);
      setFinishedGetRoleCall(true);
      return;
    }
    const { data } = await getProfile(id);
    setUser(data.user);
    const token = await getToken();
    const isAdminResponse = await getRole(token);
    const isProfessionalResponse = await isProfessional(token);
    setIsAllowed(isProfessionalResponse.status === 200 || isAdminResponse.status === 200);
    setFinishedGetRoleCall(true);
  };

  useEffect(() => {
    roleCheck();
  }, []);

  useEffect(() => {
    roleCheck();
  }, [userId]);

  return (
    <Nav isTypeform={isTypeform}>
      <Icon
        type="menu"
        style={{
          color: `${props.invert ? "#1c355e" : "#fff"}`,
          fontSize: "22px",
          padding: "8px",
        }}
        onClick={showDrawer}
      />

      <Drawer
        placement="right"
        closable={true}
        onClose={onClose}
        visible={toggle}
        width={320}
        zIndex={9999}
        drawerStyle={{ backgroundColor: "#1c355e" }}
        closeIcon={<Icon type="close" style={{ color: "#fff" }} />}
      >
        <FlexWrapper {...props} drawer>
          {isAuth || isTypeform ? (
            <>
              {!isTypeform && (
                <>
                  {user && (user.currenyReport || user.reports.length > 0) && (
                    <Link circular to="/recommendations">
                      My Recommendations
                    </Link>
                  )}
                  {finishedGetRoleCall && isAllowed && (
                    <Link circular to="/my-plans">
                      My Plans
                    </Link>
                  )}
                  <Link circular to="/account">
                    My Account
                  </Link>
                </>
              )}
              <Link circular onClick={logout}>
                Sign Out
              </Link>
            </>
          ) : (
            <ButtonLink
              to={{
                pathname: "/signin",
                destinationRoute: destinationRoute,
              }}
              navmargin="7.416px"
            >
              Sign in
            </ButtonLink>
          )}
        </FlexWrapper>
      </Drawer>
    </Nav>
  );
};

const mapStateToProps = (state) => {
  const currentReport = state.profile.currentReport;
  const isAuth = state.auth.isAuth;
  return {
    currentReport: currentReport,
    isAuth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => {
      dispatch(Actions.Creators.logout());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PrimaryNavigation);
