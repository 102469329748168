import React, { useState } from "react";
import styled from "styled-components";
import { Text, Link } from "components";
import { Row, OptWrapper } from "components/organisms/YearlyCost";
import { Collapse } from "react-collapse";
import { Currency, Spinner, setT } from "components";
import { formatCurrency } from "utils/currency";

import { nth } from "utils/ordinal";
import { getToken } from "services/utils";
import { isEmpty } from "lodash";
import {
  createNewPreference,
  disablePreference,
  optimizeV2,
} from "services/api";
import { useDispatch, useSelector } from "react-redux";
import Actions from "store/report/actions";

const OptimizationResultColumn = (props) => {
  const dispatch = useDispatch();
  const customPreferenceOne = useSelector(
    (state) => state.report.customPreferenceOne
  );
  const customPreferenceTwo = useSelector(
    (state) => state.report.customPreferenceTwo
  );

  const {
    optimization: opt,
    preferenceKey,
    otherOptimizations,
    showRows,
    preference,
    handleShow,
    checkOtherOptions,
    handleCustomPreferenceModal,
    handleSelectOtherOption,
    isSelected,
    customTitle,
    index,
    report,
  } = props;
  const {
    unexpected,
    heading,
    subHeading,
    _id: optimizationId,
    optimizationDetails,
  } = opt || {};

  const { total, charges } = unexpected || {};

  const isCustomPreferenceOne = optimizationId === customPreferenceOne._id;
  const isCustomPreferenceTwo = optimizationId === customPreferenceTwo._id;

  const { title = "", subTitle = "", lifeEvent = {} } = preference || {};

  let premiumCharges = { before: null, after: null },
    surcharges = { before: null, after: null },
    insuranceCharges = { before: null, after: null },
    outOfNetworkCharges = { before: null, after: null },
    hsaCharges = { before: null, after: null },
    optOutPayments = { before: null, after: null },
    hraCharges = { before: null, after: null };

  if (charges) {
    premiumCharges.before = charges.before.find(
      (charge) => charge.id === "premium"
    );
    premiumCharges.after = charges.after.find(
      (charge) => charge.id === "premium"
    );
    surcharges.before = charges.before.find(
      (charge) => charge.id === "surcharge"
    );
    surcharges.after = charges.after.find(
      (charge) => charge.id === "surcharge"
    );
    insuranceCharges.before = charges.before.find(
      (charge) => charge.id === "insurance"
    );
    outOfNetworkCharges.before = charges.before.find(
      (charge) => charge.id === "out-of-network"
    );
    optOutPayments.before = charges.before.find(
      (charge) => charge.id === "opt-out payment"
    );
    optOutPayments.after = charges.after.find(
      (charge) => charge.id === "opt-out payment"
    );
    hsaCharges.before = charges.before.find((charge) => charge.id === "hsa");
    hsaCharges.after = charges.after.find((charge) => charge.id === "hsa");
    hraCharges.before = charges.before.find((charge) => charge.id === "hra");
    hraCharges.after = charges.after.find((charge) => charge.id === "hra");
  }

  const renderCost = ({ type, data, event, period }) => {
    const { before, after } = data;
    const valueBefore = before ? before.total.value || before.total : 0;
    const valueAfter = after ? after.total.value || after.total : 0;
    const valueDisplay =
      period === "before"
        ? valueBefore
        : period === "after"
          ? valueAfter
          : valueBefore + valueAfter;

    const show = period ? `${type} ${period}` : type;

    if (valueDisplay && handleShow) handleShow(show, true);

    const modifier =
      type === "HSA" || type === "HRA" || type === "Opt-Out Payment" ? -1 : 1;

    return (
      <>
        <Row
          color="primary"
          justify="center"
          hide={!showRows[period ? `${type} ${period}` : type]}
        >
          <Text black small>
            {formatCurrency(valueDisplay * modifier)}
          </Text>
        </Row>
      </>
    );
  };

  const onRemoveCustom = async (preference) => {
    const reset =
      preference === 1
        ? { customPreferenceOne: {} }
        : { customPreferenceTwo: {} };

    const token = await getToken();
    const custom = preference === 1 ? "custom" : "custom2";

    await disablePreference(report._id, custom, token);
    dispatch(Actions.Creators.switchState(reset));
  };

  const selectAsCustom = async () => {
    const {
      birthParent: { externalId: birthParentExternalId },
      partner: { externalId: partnerExternalId },
      childrenJoin,
      join,
    } = optimizationDetails;
    const newCoverage = {
      title: `Custom Coverage #${isEmpty(customPreferenceOne) ? "1" : "2"}`,
      subTitle: "",
      apply: true,
      join: join,
      cost: "lowest",
      childrenJoin: childrenJoin,
      include: {
        birthParentPlans: [birthParentExternalId],
        partnerPlans: [partnerExternalId],
      },
      exclude: {
        birthParentPlans: [],
        partnerPlans: [],
      },
      lifeEvent: {
        name: null,
        date: null,
        allowSwitch: false,
      },
      bills: {
        inNetworkParent: 25285,
        hospitalVisits: 1,
      },
    };
    const token = await getToken();
    const custom = isEmpty(customPreferenceOne) ? "custom" : "custom2";
    const res = await createNewPreference(
      report._id,
      custom,
      newCoverage,
      token
    );

    const opt =
      res.status === 200 &&
      (await optimizeV2({ reportId: report._id, preferenceKey: custom }));

    if (opt.status === 200) {
      let customPreference = opt.data[0].selected;
      const customState = isEmpty(customPreferenceOne)
        ? {
          customPreferenceOne: customPreference,
        }
        : {
          customPreferenceTwo: customPreference,
        };
      dispatch(Actions.Creators.switchState(customState));
    }
  };

  const renderCustomSelect = () => {
    if (isCustomPreferenceOne) {
      return (
        <Link darkred fontSize="13" text onClick={() => onRemoveCustom(1)}>
          Remove Custom Preference
        </Link>
      );
    } else if (isCustomPreferenceTwo) {
      return (
        <Link darkred fontSize="13" text onClick={() => onRemoveCustom(2)}>
          Remove Custom Preference
        </Link>
      );
    } else {
      return (
        <Link blue fontSize="13" text onClick={selectAsCustom}>
          Select Custom Preference
        </Link>
      );
    }
  };

  const renderTitle = () => {
    if (index === 0)
      return (
        <Text blue fontSize="20" bold center>
          Recommended
        </Text>
      );

    if (isCustomPreferenceOne)
      return (
        <Text blue fontSize="20" bold center>
          Custom Preference #1
        </Text>
      );

    if (isCustomPreferenceTwo)
      return (
        <Text blue fontSize="20" bold center>
          Custom Preference #2
        </Text>
      );
  };

  const PreferenceHeader = ({ index }) => {
    const title = `${index > 1 ? index + nth(index) : ""} Lowest Spend`;
    return (
      <>
        <Text small center headerColor bold>
          {customTitle || title}
        </Text>
        {index > 1 && (
          <div style={{ marginTop: 2.5 }}>{renderCustomSelect()}</div>
        )}
      </>
    );
  };

  const SelectOptionHeader = ({ isSelected, optimizationId }) => {
    return isSelected ? (
      <Text small center headerColor bold>
        Current Display
      </Text>
    ) : (
      <Link
        blue
        fontSize="19"
        text
        onClick={() => handleSelectOtherOption(optimizationId)}
      >
        Show This
      </Link>
    );
  };

  return (
    <>
      <Row>{renderTitle()}</Row>
      <OptWrapper>
        <Row
          height="100px"
          color="primary"
          col
          bRadius="8px"
          border="2px solid #D0D5DD"
        >
          {!handleSelectOtherOption ? (
            <PreferenceHeader
              // {...{ title, handleCustomPreferenceModal, preferenceKey, subTitle }}
              index={index + 1}
            />
          ) : (
            <SelectOptionHeader {...{ isSelected, optimizationId }} />
          )}
        </Row>
        <Row
          header
          color="secondary"
          justify="center"
          col
          bRadius="8px"
        >
          {opt ? (
            <>
              <Text small center headerColor bold>
                {heading}
              </Text>
            </>
          ) : (
            <Spinner small />
          )}
          {subHeading && (
            <>
              <Text
                black
                fontSize="13"
                lineHeight="16"
                margin="4px"
                italic
                center
              >
                {subHeading[0]}
              </Text>
              {subHeading[1] && (
                <>
                  <div
                    style={{ width: "150px", borderTop: "1px solid lightgrey" }}
                  ></div>
                  <Text
                    black
                    fontSize="13"
                    lineHeight="16"
                    margin="4px"
                    italic
                    center
                  >
                    {subHeading[1]}
                  </Text>
                </>
              )}
            </>
          )}
          {otherOptimizations && otherOptimizations.length ? (
            <Link
              blue
              fontSize="13"
              text
              onClick={checkOtherOptions}
              style={{ textAlign: "center" }}
            >
              {`and ${otherOptimizations.length} other${otherOptimizations.length > 1 ? "s" : ""
                }`}
            </Link>
          ) : null}
        </Row>

        <Collapse isOpened={showRows.total}>
          {renderCost({
            type: "Premium",
            data: premiumCharges,
            event: lifeEvent.name,
          })}
          {renderCost({
            type: "Surcharge",
            data: surcharges,
            event: lifeEvent.name,
          })}
          {renderCost({
            type: "Insurance",
            data: insuranceCharges,
            event: lifeEvent.name,
          })}
          {renderCost({
            type: "Out-Of-Network",
            data: outOfNetworkCharges,
            event: lifeEvent.name,
          })}
          {renderCost({
            type: "Opt-Out Payment",
            data: optOutPayments,
            event: lifeEvent.name,
          })}
          {renderCost({
            type: "HSA",
            data: hsaCharges,
            event: lifeEvent.name,
            period: "before",
          })}
          {renderCost({
            type: "HRA",
            data: hraCharges,
            event: lifeEvent.name,
            period: "before",
          })}
        </Collapse>
        <Row
          color='secondary'
          justify="center"
          style={{
            boxShadow: '60px 0px #E8EEFA'
          }}
        >
          <Text medium bold center headerColor>
            {charges && formatCurrency(total)}{" "}
          </Text>
        </Row>
      </OptWrapper>
    </>
  );
};

export default OptimizationResultColumn;